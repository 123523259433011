import { createContext, useContext, useEffect, useState } from "react";
import fire, { generateUserDocument } from "../utilities/firebase_app";

export const UserContext = createContext(undefined);
export const setUserContext = createContext<any>(undefined);

export function useSetUser() {
  return useContext(setUserContext);
}

export function UserProvider({ children }: any) {
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    fire.auth().onAuthStateChanged((userAuth: any) => {
      generateUserDocument(userAuth).then((user: any) => setUser(user));
    });
  }, []);

  return (
    <UserContext.Provider value={user}>
      <setUserContext.Provider value={setUser}>
        {children}
      </setUserContext.Provider>
    </UserContext.Provider>
  );
}
