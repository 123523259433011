import axios from 'axios';

export async function getToken() {
    let token:any=null;
    await axios.get('https://us-central1-entertainment-coach-db.cloudfunctions.net/braintree/getToken').then((response:any)=>{
        token=response.data.clientToken;
    }).catch((err:any)=>{console.error(err)});
    return token;
}

export async function makePayment(nonce:any,amount:string){
    let response:any
    response=await axios.post(
        'https://us-central1-entertainment-coach-db.cloudfunctions.net/braintree/payment',
        {nonce,amount}
    );
    return response

}