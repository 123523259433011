import {useContext, useEffect, useState} from "react";
import {getCoachSessions} from "../../../../utilities/firebase_app";
import {UserContext} from "../../../../providers/UserProvider";
import CoachSessionCard from "./coachSessionCard";
import Schedule from "../../schedule/schedule";
import { colors } from "../../../../utilities/config";

export default function CoachSession(){
    const [sessions,setSession]=useState<any>();
    const user:any=useContext(UserContext);
    useEffect(() => {
        getCoachSessions(user.email).then(data => {
            const sessionsList:any=[];
            (data.docs).map(doc=>sessionsList.push({...doc.data(),id:doc.id}));
            setSession(sessionsList);
        })
    }, [user.email])
    return(
        <div className="col-12 text-center d-flex flex-column align-items-center " >
            {sessions&&sessions[0]&&<div className="col-12 col-md-9 my-3 normal-shadow-left-right py-3 h5 "
                 style={{backgroundColor: colors.primary,color:colors.text_primary, borderRadius: "10px"}}>YOUR AVAILABILITY
            </div>}
            <div className=" col-12 col-md-9 mb-4">
                {sessions&&sessions[0]&&<CoachSessionCard key={sessions[0].dateTheSessionAdded} session={sessions[0]}/>}
            </div>
            {sessions&&<div className="col-12 col-md-9 my-3 normal-shadow-left-right py-3 h5 "
                 style={{backgroundColor: colors.primary,color:colors.text_primary, borderRadius: "10px"}}>{(sessions&&sessions[0])?"UPDATE AVAILABILITY":"ADD AVAILABILITY"}
            </div>}
            {sessions&&(sessions[0]?<Schedule propPrice={sessions[0].priceOfTheSession} sessionID={sessions[0].id} propFrom={sessions[0].startHour} propDays={sessions[0].daysOfThesession} propTo={sessions[0].noOfSlots+sessions[0].startHour}/>:<Schedule propFrom={-1} />)}


        </div>
    )
}