import { useEffect, useState } from "react";
import DropIn from "braintree-web-drop-in-react";
import { getToken, makePayment } from "../utilities/braintree";
import {
  useConvertedPrice,
  useCurrencySymbol,
} from "../providers/metadata_context";

function BraintreeLayout({
  amount,
  onSuccess,
}: {
  amount: string;
  onSuccess: Function;
}) {
  const [clientToken, setClientToken] = useState(null);
  const [instance, setInstance] = useState<any>(null);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState(
    "Connecting to payment..."
  );
  const [loading, setLoading] = useState(false);
  const convertedPrice = useConvertedPrice(parseInt(amount));
  const currencySymbol = useCurrencySymbol();
  useEffect(() => {
    getToken().then((token) => {
      if (token) {
        setPaymentMessage("");

        setClientToken(token);
      } else {
        setPaymentMessage("Unable to connect to payment server.");
      }
    });
  }, []);

  async function buy() {
    setLoading(false);
    const { nonce } = await instance.requestPaymentMethod();
    makePayment(nonce, convertedPrice.toString())
      .then((response: any) => {
        if (response.data.success) {
          setPaymentStatus(true);
          onSuccess();
        } else {
          setLoading(false);
          setPaymentMessage(response.data.message + " Please try again.");
        }
      })
      .catch((reason: any) => {
        console.log(reason);
        setPaymentMessage("Payment failed. Please try again.");
      });
  }

  return (
    <div>
      <div className="h6">
        Amount Payable:&nbsp;
        {currencySymbol}
        {convertedPrice}
      </div>
      {paymentStatus ? (
        <div className="h4 text-center bg-white">
          PAYMENT SUCCESSFUL
          <br />
          <small>Redirecting you in 5 seconds...</small>
        </div>
      ) : (
        <div>
          {clientToken && (
            <DropIn
              options={{
                authorization: clientToken,
                paypal: {
                  flow: "checkout",
                  amount,
                  currency: "USD",
                },
              }}
              onInstance={(instance) => setInstance(instance)}
            />
          )}
          <h5 className="text-danger m-2">{paymentMessage}</h5>
          {instance && (
            <button
              disabled={loading}
              className="btn btn-success rounded-pill"
              onClick={buy}
            >
              {loading ? "Please wait" : "Buy"}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default BraintreeLayout;
