import {useUserJoined} from "../providers/AgoraProvider";
import {useHistory} from 'react-router-dom';
import { colors } from "../utilities/config";

export default function IncomingCall(){
    const history=useHistory();
    const userJoined=useUserJoined();
    const studentName=userJoined.slice(0,userJoined.indexOf("@"));
    const sessionID=userJoined.slice(userJoined.indexOf("@")+1,)
    function joinSession(){
        history.push('/start-session/'+sessionID)
    }
    return (
        <div>
            {userJoined!==""&&
            <div className="bg-danger d-flex justify-content-between py-2 px-3 fixed-bottom align-items-center">
                <div className="h6" style={{color:colors.text_primary}}>Incoming call from {studentName}</div>
                <button className="btn btn-success rounded-pill light-shadow-left-right" onClick={joinSession}>Attend</button>
            </div>
            }




        </div>
    )
}