export function dateCheck(timeSlot:string,dateSelectedByStudentForSession:string){
    const [start_time,end_time]=getDate(timeSlot,dateSelectedByStudentForSession);
    let curr = new Date();
    if (curr.getTime()>end_time.getTime()) {
        return -1;
    }
    if (curr.getTime()<start_time.getTime()) {
        return 1;
    }
    return 0;
}

export function getDate(timeSlot:string,dateSelectedByStudentForSession:string){
    const d = new Date(dateSelectedByStudentForSession) ;
    let start_time = new Date(d.toDateString());
    let end_time = new Date(d.toDateString());
    let timeSlotRaw=timeSlot.split(" ").filter((x:string)=>x!==""&&x!=="-"&&x!==":");
    let sessionStartHour: number = parseInt(timeSlotRaw[0]);
    let sessionEndHour: number = parseInt(timeSlotRaw[3]);
    let sessionStartMinute: number = parseInt(timeSlotRaw[1]);
    let sessionEndMinute: number = parseInt(timeSlotRaw[4]);
    if (sessionStartHour === 12) sessionStartHour = 0;
    if (sessionEndHour === 12) sessionStartHour = 0;
    if (timeSlotRaw[2] === 'PM') sessionStartHour += 12;
    if (timeSlotRaw[5] === 'PM') sessionEndHour += 12;
    start_time = new Date(start_time.getTime() + (sessionStartHour * 60+sessionStartMinute) * 60000);
    end_time = new Date(end_time.getTime() + (sessionEndHour * 60+sessionEndMinute) * 60000);

    return [start_time,end_time];
}