import {useState} from 'react';
import fire from "../../utilities/firebase_app";
import config from "../../utilities/config";

export default function ResetPassword() {
    const [email, changeEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailSuccess, setEmailSuccess] = useState("");

    function validateEmail(email: any) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function localFormValidation(){
        if(email===''){
            setEmailError('This field is required');
            return false;
        }
        if(!validateEmail(email)){
            setEmailError("Not a valid e-mail");
            return false;
        }
        return true;
    }
    function onLoginFormSubmit(e: any) {
        e.preventDefault();
        setEmailError('');
        setEmailSuccess('');
        if(!localFormValidation())return;
        fire.auth().sendPasswordResetEmail(email).then(
            ()=>{setEmailSuccess('Email sent successfully!');changeEmail('')}
        ).catch((error:any)=>{
            switch (error.code) {
                case "auth/network-request-failed": {
                    setEmailError("Unable to connect to the internet");
                    break;
                }
                case "auth/user-not-found":{
                    setEmailError("No such user found");
                    break;
                }
                default:setEmailError(error.code);
            }
        });

    }

    return (
        <div className="text-center">
            <div className="mt-2 mb-4 h2">Reset Password</div>
            <img src={config.main_logo} style={{height:"250px"}} className="mb-4" alt={"entertainment coach main logo"}/>
            <form className="form-group container text-center" noValidate={true} onSubmit={onLoginFormSubmit} style={{width: 0.75*window.innerWidth}}>
                <div className="container mt-3 h6 text-white">
                    Enter your email address to reset
                </div>
                <input required value={email} onChange={(e) => changeEmail(e.target.value)} type="email"
                       placeholder="Email"
                       className="form-control mt-3 normal-shadow-left-right form-bevel"/>
                <div className="text-danger container text-left small mt-1">{emailError}</div>
                <div className="text-success container text-left small mt-1">{emailSuccess}</div>
                <input type="submit" value="SEND RESET EMAIL" className="btn btn-dark rounded-pill my-4 px-3 py-2"/>
            </form>
        </div>
    )
}