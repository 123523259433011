import React, { createContext, useContext, useEffect, useReducer } from "react";
import { getConversionRates } from "../utilities/firebase_app";

const initialState = {
  rates: { pound: 0.72 },
  currency: "USD",
};

const metaContext = createContext<any>([initialState, undefined]);

function reducer(state: any, action: any) {
  switch (action.type) {
    case "SET_POUNDS": {
      return { ...state, rates: { ...state.rates, pound: action.payload } };
    }
    case "SET_CURRENCY": {
      return { ...state, currency: action.payload };
    }
  }
}

export function useMetaData() {
  return useContext(metaContext);
}
export function useConvertedPrice(price: number) {
  const [meta] = useContext(metaContext);
  if (meta.currency === "Pounds") {
    return Math.ceil(price * meta.rates.pound);
  } else return price;
}
export function useCurrencySymbol() {
  const [meta] = useContext(metaContext);
  if (meta.currency === "Pounds") {
    return "£";
  } else return "$";
}
export default function MetadataContext({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    getConversionRates().then((doc: any) => {
      if (doc && doc.exists) {
        dispatch({ type: "SET_POUNDS", payload: doc.data().pound });
      }
    });
  }, []);
  return (
    <metaContext.Provider value={[state, dispatch]}>
      {children}
    </metaContext.Provider>
  );
}
