import CoachesList from "../../../coachesList";
import { useContext, useEffect, useState } from "react";
import config, { colors } from "../../../../utilities/config";

import SkillCard from "../../../skill_selection/skillCard";
import { UserContext } from "../../../../providers/UserProvider";
import { useParams } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { useMetaData } from "../../../../providers/metadata_context";

export default function HomeStudent() {
    const params: any = useParams();

    const categories = params.category
        ? params.category.split("&").map((x: any) => {
              if (parseInt(x) < 15) return parseInt(x);
              else return null;
          })
        : [];
    const user: any = useContext(UserContext);
    const categoryProvided: boolean =
        categories.length > 0 && !categories.includes(null);
    const [meta, dispatchMeta] = useMetaData();
    const [searchText, setSearchText] = useState<string>("");
    const [sortBy, setSortBy] = useState("a-z");
    const [filterByPopup, setFilterByPopup] = useState(!categoryProvided);
    const [selected, changeSelected] = useState<Array<number>>(
        categoryProvided ? categories : []
    );
    useEffect(() => {
        if (categories.length > 0 && !categories.includes(null)) {
            changeSelected(categories);
        } else setFilterByPopup(true);
    }, [params.category]);

    function onClose() {
        setFilterByPopup(false);
    }

    function addToSelect(skill: string[]) {
        changeSelected((selectedOld) => {
            selectedOld.push(config.all_skills.indexOf(skill));
            return [...selectedOld];
        });
    }

    function removeFromSelect(skill: string[]) {
        changeSelected((selectedOld) => {
            selectedOld.splice(
                selectedOld.indexOf(config.all_skills.indexOf(skill)),
                1
            );
            return [...selectedOld];
        });
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <Modal
                show={filterByPopup}
                dialogClassName="homeModalDialog "
                contentClassName="homePopup"
                onHide={() => setFilterByPopup(false)}
            >
                <Modal.Header className="align-items-center text-center my-4 d-flex justify-content-center w-100 text-dark searchPopupHeading">
                    <div className="flex-grow-1">
                        {user.isStudent
                            ? "What do you want to learn"
                            : "Select skills to filter"}
                    </div>
                    <img alt="close" onClick={() => setFilterByPopup(false)} className="pointer-on-hover m-2" src="https://img.icons8.com/material-two-tone/24/000000/multiply.png"/>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap w-100 justify-content-center">
                        {config.all_skills.map((skill) => (
                            <SkillCard
                                key={skill[0]}
                                skill={skill}
                                addToSelect={addToSelect}
                                removeFromSelect={removeFromSelect}
                                popUpCard={true}
                                selected={selected.includes(
                                    config.all_skills.indexOf(skill)
                                )}
                                height={50}
                                width={50 * 1.5}
                            />
                        ))}
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-center ">
                    <button
                        style={{ backgroundColor: colors.primary }}
                        className="btn mb-4 col-4 btn-block"
                        onClick={onClose}
                    >
                        Let's go
                    </button>
                </div>
            </Modal>
            <div className="d-flex flex-wrap col-11 mb-md-3 justify-content-between">
                <div
                    style={{ backgroundColor: colors.secondary }}
                    className="p-2 mb-3 m-md-0 rounded-pill form-inline col-12 col-md-5 d-flex flex-nowrap"
                >
                    <input
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        className="searchInput form-control flex-grow-1 "
                        placeholder="Search name"
                    />
                    <button
                        className="btn  p-0"
                        style={{
                            backgroundColor: colors.primary,
                            borderRadius: "50%",
                            minHeight: "35px",
                            minWidth: "35px",
                        }}
                    >
                        <img
                            src="https://img.icons8.com/material-two-tone/20/000000/search.png"
                            height={20}
                            width={20}
                            alt="search"
                        />
                    </button>
                </div>
                <div className="d-flex align-items-center flex-wrap justify-content-center col-12 col-md-auto">
                    <Dropdown id="dropdown-basic-button" className="m-2">
                        <Dropdown.Toggle variant="warning">
                            {`Currency : ${meta.currency}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    dispatchMeta({
                                        type: "SET_CURRENCY",
                                        payload: "USD",
                                    });
                                }}
                            >
                                USD
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    dispatchMeta({
                                        type: "SET_CURRENCY",
                                        payload: "Pounds",
                                    });
                                }}
                            >
                                Pounds
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown id="dropdown-basic-button" className="m-2">
                        <Dropdown.Toggle
                            variant="warning "
                            className="text-capitalize"
                        >
                            {`Sort By : ${sortBy}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setSortBy("a-z");
                                }}
                            >
                                A-Z
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setSortBy("z-a");
                                }}
                            >
                                Z-A
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setSortBy("highest rated");
                                }}
                            >
                                Highest Rated
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setSortBy("price low-high");
                                }}
                            >
                                Price (Low-High)
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setSortBy("price high-low");
                                }}
                            >
                                Price (High-Low)
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button
                        className="btn border-warning btn-outline-warning rounded-pill m-2"
                        onClick={() => setFilterByPopup(true)}
                    >
                        Search Skill
                    </button>
                </div>
            </div>
            {selected.length !== 0 && (
                <div className="text-white text-left col-11 h5 my-2">
                    <span className="text-dark">Currently Showing:</span>
                    {selected.map((skill) => (
                        <span
                            key={skill}
                            className="my-1 mx-2 px-2 badge badge-warning rounded-pill text-capitalize"
                        >
                            {config.all_skills[skill][0]}
                            <span
                                onClick={() => {
                                    changeSelected((old) =>
                                        old.filter((x) => x !== skill)
                                    );
                                }}
                                className="badge pointer-on-hover badge-danger rounded-pill ml-2"
                            >
                                x
                            </span>
                        </span>
                    ))}
                </div>
            )}
            <CoachesList
                searchText={searchText}
                skills={selected.length === 0 ? undefined : selected}
                sortBy={sortBy}
            />
        </div>
    );
}
