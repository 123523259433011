import React, {useContext, useState} from 'react';
import config from "../../../utilities/config";
import './categories.scss';
import {useHistory} from "react-router";
import {UserContext} from "../../../providers/UserProvider";

const Categories = ({openModal}: { openModal: () => void }) => {
    const history = useHistory()
    const user: any = useContext(UserContext);
    const [selected, setSelected] = useState<number[]>([]);

    function toggle(index: number) {
        if (selected.includes(index)) {
            setSelected(selected.filter(ele => ele !== index))

        } else setSelected(old => [...old, index]);
    }

    function onNext() {
        if (user) {
            history.push('/search/' + selected.join('&'))
        } else openModal()
    }

    return (
        <div>
            <div className="categories-list">
                {config.all_skills.map((skill, index) => {
                        const isSelected = selected.includes(index);
                        return <span className="border-animation">
                            <div onClick={() => toggle(index)}
                                 className={"category-card pointer-on-hover " + (isSelected ? "category-card-selected" : "")}>
                            <img className="category-image" src={skill[1]} alt={skill[0]}/>
                            <div
                                className={"category-heading text-capitalize flex-grow-1 text-left " + (index % 2 === 0 && "category-alt-heading")}>{skill[0]}</div>
                            <div className="form-check form-check-inline align-items-center">
                                <input className="form-check-input pointer-on-hover" type="checkbox" value={1}
                                       checked={isSelected}
                                       id="flexCheckIndeterminate"/>
                            </div>
                            </div></span>
                    }
                )}

            </div>
            <div className="text-center my-4">
                <button onClick={onNext} className="btn btn-light rounded-pill col-9 col-md-2">Next </button>
            </div>
        </div>

    );
};

export default Categories;
