import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import AgoraRTC, {IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack,} from "agora-rtc-sdk-ng";
import {UserContext} from "../../providers/UserProvider";
import {
    getAgoraToken,
    options,
    useClient,
    useRemoteAudioTrack,
    useRemoteVideoTrack,
} from "../../providers/AgoraProvider";
import {getEnrolledSession, getUserDocument,} from "../../utilities/firebase_app";
import {getDate} from "../../utilities/utils";
import { colors } from "../../utilities/config";

function SessionStart() {

    const history = useHistory();
    const {sessionID}: { sessionID: string } = useParams();
    const user: any = useContext(UserContext);
    const client: IAgoraRTCClient | undefined = useClient();
    const remoteVideoTrack = useRemoteVideoTrack();
    const remoteAudioTrack = useRemoteAudioTrack();
    const [status,setStatus]=useState("OFFLINE");
    const [countdown,setCountdown]=useState('');
    const [studentRemoteVideoTrack, setStudentRemoteVideoTrack] = useState(
        useRemoteVideoTrack()
    );
    const [studentRemoteAudioTrack, setStudentRemoteAudioTrack] = useState(
        useRemoteAudioTrack()
    );

    const [localAudioTrack, setLocalAudioTrack] = useState<IMicrophoneAudioTrack | undefined>(undefined);
    const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack | undefined>(undefined);
    const [session, setSession] = useState<any>(undefined);
    const [cameras, setCameras] = useState<any>([]);
    const [cameraIndex, setCameraIndex] = useState(0);
    const [volume, setVolume] = useState(100);
    const [remoteHeight, setRemoteHeight] = useState<number>(100);
    const mounted = useRef(true);

    //useEffects
    useEffect(() => {
        if(!session)return;
        const [,end_time]=getDate(session.timeSlot,session.dateSelectedByStudentForSession);
        let now = new Date().getTime();
        let distance = end_time.getTime() - now;

        if (distance > 0){const inter=setInterval(() => {
            let now = new Date().getTime();
            let distance = end_time.getTime() - now;
            if (distance <= 0){
                clearInterval(inter);
                onLeave();
                setTimeout(()=>alert('Session time expired!'), 20);
            }
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setCountdown(`${minutes<10?0:''}${minutes}:${seconds<10?0:''}${seconds}`);

        }, 1000)}
    }, [session,session?.timeSlot,session?.dateSelectedByStudentForSession])
    useEffect(() => {
        getEnrolledSession(sessionID).then((doc) => {
            setSession(doc.data());
        });
        const nav = document.getElementById("navigation")?.offsetHeight;
        const bottom = document.getElementById("bottom")?.offsetHeight;
        document.getElementById("main-div")?.classList.remove("mt-5");
        const screen = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
        );
        if (bottom && nav) setRemoteHeight(screen - bottom - nav);

        return () => {
            document.getElementById("main-div")?.classList.add("mt-5");
            mounted.current = false;
        };
    }, [sessionID]);

    useEffect(() => {
        return () => {
            if (!mounted.current) {
                localVideoTrack?.close();
                localAudioTrack?.close();
                setLocalVideoTrack(undefined);
                setLocalAudioTrack(undefined);
                client?.unpublish();
                if(user?.isStudent) client?.leave();
            }
        };
    }, [localVideoTrack, localAudioTrack, client,user?.isStudent]);

    useEffect(() => {
        if (!user) return;
        if (!user.isStudent ) {
            if(remoteVideoTrack && remoteAudioTrack){
                remoteVideoTrack.play("remote");
                remoteAudioTrack.play();
                setStatus("ONLINE")
            }
            else setStatus("OFFLINE")

        }
        if (user.isStudent && studentRemoteVideoTrack && studentRemoteAudioTrack) {
            studentRemoteVideoTrack.play("remote");
            studentRemoteAudioTrack.play();
        }
    }, [
        remoteVideoTrack,
        remoteAudioTrack,
        studentRemoteAudioTrack,
        studentRemoteVideoTrack,
        user,
    ]);
    useEffect(() => {
        function publish() {
            if (client) {
                AgoraRTC.getCameras().then((cameras) => {
                    setCameras(cameras);
                    AgoraRTC.createMicrophoneAndCameraTracks().then(
                        ([audioTrack, videoTrack]) => {
                            setLocalVideoTrack(videoTrack);
                            const localPlayer: HTMLElement | null = document.getElementById(
                                "me"
                            );
                            if (localPlayer) videoTrack.play(localPlayer);
                            setLocalAudioTrack(audioTrack);
                            console.log(client);
                            client.publish([audioTrack, videoTrack]);
                        }
                    );
                });
            }
        }
        if(session){
            if(user.email!==session.coachEmail&&user.email!==session.studentEmail){
                alert("You are not authorized to attend the session!")
                onLeave();
            }
            const [start_time,end_time]=getDate(session.timeSlot,session.dateSelectedByStudentForSession);
            let curr = new Date();
            if (curr.getTime()>end_time.getTime()) {
                onLeave();
                setTimeout(()=>alert('Session time expired!'), 20);

            }
            if (curr.getTime()<start_time.getTime()) {
                onLeave();
                setTimeout(()=>alert('Session not started!'), 20);
            }
        }
        if (session && user.isStudent && client && user) {

            getUserDocument(session.coachEmail).then((coach: any) => {
                if (!client.channelName)
                    client
                        .join(
                            options.appId,
                            coach.username,
                            getAgoraToken(user.username + "@" + sessionID, coach.username),
                            user.username + "@" + sessionID
                        )
                        .then(() => {
                            client.on("user-published", async (remoteUser, mediaType) => {
                                setStatus("ONLINE")
                                if (remoteUser.uid === user.username) return;
                                await client.subscribe(remoteUser, mediaType);
                                setStudentRemoteVideoTrack(remoteUser.videoTrack);
                                setStudentRemoteAudioTrack(remoteUser.audioTrack);
                            });
                            client.on("user-unpublished",()=>{
                                setStatus("OFFLINE")
                            })
                            publish();
                        });
                else {
                    publish();
                }
            });
        } else if (session && !user.isStudent && client && user) {
            if(client.localTracks.length===0){
                if(client.connectionState==="CONNECTED")publish();
                else client.on("connection-state-change",(state)=>{
                    if(state==="CONNECTED")publish();
                })
            }
        }
    }, [session, client, user, sessionID]);

    function onLeave() {
        history.push("/profile")
    }

    function onSwitch() {
        localVideoTrack
            ?.setDevice(cameras[(cameraIndex + 1) % cameras.length].deviceId)
            .then(() =>
                setCameraIndex(
                    (cameraIndexOld: number) => (cameraIndexOld + 1) % cameras.length
                )
            );
    }

    function onMute() {
        localAudioTrack?.setVolume(volume === 100 ? 0 : 100);
        setVolume(volume === 100 ? 0 : 100);
    }

    return (
        <div className="text-center d-flex flex-column align-items-center mb-5 position-relative">
            {localVideoTrack && localVideoTrack.isPlaying}
            {status==="OFFLINE"&&<div className="bg-yellow w-100">The user is offline</div>}
            <div
                id="remote"
                style={{
                    height: remoteHeight.toString() + "px",
                    width: "100%",
                    backgroundColor: "black",
                    color:colors.text_primary
                }}
            />
            <div style={{top:25,right:15,opacity:'50%'}} className='rounded bg-dark p-2 px-3 position-absolute text-white'>
                <div className="h3 m-0 p-0">{countdown}</div>
                <small className="m-0 p-0">Time left</small>
            </div>

            <div className="fixed-bottom text-left">
                <div
                    id="me"
                    className="bg-dark m-2"
                    style={{height: "15vh", width: "20vh"}}
                />
                <div className="bg-dark p-3 text-center" id="bottom">


                    <button
                        className="btn btn-dark rounded-circle mx-2 text-center"
                        onClick={onMute}
                        style={{height: 50, width: 50, backgroundColor:volume===100?"black":"#DC3545"}}
                    >
                        {volume === 100 ? <img className="invert"
                                             src="https://img.icons8.com/material-rounded/24/000000/no-microphone.png" alt="unmuted"/> :
                            <img className="invert" src="https://img.icons8.com/material/24/000000/no-microphone.png" alt="muted"/>}
                    </button>
                    {cameras.length > 1 && (
                        <button
                            className="btn btn-dark rounded-circle mx-2"
                            onClick={onSwitch}
                            style={{height: 50, width: 50,backgroundColor:"black"}}

                        >
                            <img alt="switch camera" className="invert" src="https://img.icons8.com/android/24/000000/switch-camera.png"/>
                        </button>
                    )}
                    <button
                        className="btn btn-danger rounded-circle mx-2"
                        onClick={onLeave}
                        style={{height: 49, width: 49}}
                    >

                        <img alt="disconnect" className="invert" src="https://img.icons8.com/material-outlined/22/000000/call-disconnected.png"/>
                    </button>

                </div>
            </div>
        </div>
    );
}

export default SessionStart;
