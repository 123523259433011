import config, { colors } from "../../../utilities/config";
import {useHistory} from 'react-router-dom';
import gStar1 from '../../../static/gold_star_first.png';
import gStar2 from '../../../static/gold_star_second.png';
import bStar1 from '../../../static/black_star_first.png';
import bStar2 from '../../../static/black_star_second.png';
import {useEffect, useState} from "react";
import fire, {getUserDocument} from "../../../utilities/firebase_app";
import {getDate} from "../../../utilities/utils";

export default function SessionsCard({session, pastSession, isStudent,refresh}: any) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const d = new Date(session.dateSelectedByStudentForSession) ;
    let dateString = days[d.getDay()] + ', ' + monthNames[d.getMonth()] + ' ' + d.getDate();

    const history = useHistory();
    const [countdown, setCountdown] = useState('');
    const [coach, setCoach] = useState<any>(undefined);
    const [currentRating, setCurrentRating] = useState(session.ratingGivenByStudent ? session.ratingGivenByStudent : 3);


    useEffect(() => {
        const d_day =getDate(session.timeSlot,session.dateSelectedByStudentForSession)[0];
        let now = new Date().getTime();
        let distance = d_day.getTime() - now;
        if (distance < 0) {
            setCountdown("LIVE");
            if(distance>=-3600000){
                const interval1=setInterval(()=>{
                    let now = new Date().getTime();
                    let distance = d_day.getTime() - now;
                    if(distance<-3600000){
                        refresh();
                        clearInterval(interval1);
                    }
                },1000)
            }

        } else {const interval2=setInterval(() => {
            let now = new Date().getTime();
            let distance = d_day.getTime() - now;

            if (distance < 0) {
                clearInterval(interval2)
                refresh();
                return;
            }
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (days !== 0) {
                setCountdown(`${days} Days ${hours} Hours`)
                return;
            } else if (hours !== 0) {
                setCountdown(`${hours} Hours ${minutes} Minutes`);

                return;
            } else if (minutes !== 0) {
                setCountdown(`${minutes} Minutes ${seconds} Seconds`);
                return;
            }
            setCountdown(`${seconds} Seconds`);

        }, 1000)}
        getUserDocument(isStudent ? session.coachEmail : session.studentEmail).then(data => setCoach(data));
    }, [isStudent, session.coachEmail,session.studentEmail,session.timeSlot,session.dateSelectedByStudentForSession])

    function onSessionStart() {
        history.push('/start-session/' + session.id)
    }

    function onRatingChange(rate: number) {
        setCurrentRating(rate)
        onRatingSubmit(rate);
    }

    function onRatingSubmit(rate: number = currentRating) {
        fire.firestore().collection('enrolledSessions').doc(session.id).update({
            ratingGivenByStudent: rate
        }).then(
            () => {
                fire.firestore().collection('users').doc(session.coachEmail).get().then(doc => {
                    if (!doc.exists) return
                    const coach = doc.data();


                    let newRating, oldRating = -1, oldSessionsRated = -1, oldStudentRating = -1;

                    if (session.ratingGivenByStudent) {
                        oldStudentRating = session.ratingGivenByStudent
                    }
                    session.ratingGivenByStudent = currentRating;
                    if (coach && coach.rating) oldRating = coach.rating;
                    if (coach && coach.sessionsRated) oldSessionsRated = coach.sessionsRated;

                    if (oldStudentRating !== -1) {
                        if (oldRating !== -1) {
                            newRating = (oldRating) + (-oldStudentRating + currentRating) / oldSessionsRated
                        }
                    } else {
                        if (oldRating === -1 || oldSessionsRated === -1 || oldSessionsRated === 0) {
                            newRating = currentRating
                        } else {
                            newRating = (oldRating * oldSessionsRated + currentRating) / (oldSessionsRated + 1)
                        }
                    }
                    fire.firestore().collection('users').doc(session.coachEmail).update({
                        rating: newRating,
                        sessionsRated: (oldSessionsRated === -1 ? 1 : oldStudentRating === -1 ? oldSessionsRated + 1 : oldSessionsRated)
                    })

                })

            }
        )
    }

    return (
        <div className="d-flex my-3 p-4 normal-shadow-left-right align-items-center flex-wrap justify-content-center"
             style={{borderRadius: "10px", backgroundColor: colors.secondary}}>
            <div className="flex-grow-1 d-flex align-items-center text-left  m-2 m-md-3 mx-md-4">
                <img src={(coach && coach.pp) ? coach.pp : config.default_pp} className="rounded-pill" alt="coach"
                     height={80} width={80}/>
                <div className="d-flex flex-column ml-3">
                    <div className="coachNameSessionCard">{coach && coach.fullname}</div>
                    <div className="text-left">
                        <div className="dateString my-2">{dateString}</div>
                        <div className="timeString my-2">{session.timeSlot}</div>
                    </div>
                </div>
            </div>
            {!pastSession &&
            <div className="mx-3 text-center text-md-right mt-3 mt-md-0">
                {countdown !== "LIVE" ? <div>
                    <div className="session-starts-in-text">Session starts in:</div>
                    <div className="countdown-text">{countdown}</div>
                </div> : <div>
                    <button className="btn btn-outline-warning rounded-pill" onClick={onSessionStart}>Go Live</button>
                </div>}
            </div>}

            {(pastSession) && <div className="mt-3 mt-md-0">
                {isStudent && <div className="mx-3 ">Rate session</div>}
                {!isStudent && <div className="mx-3 ">Rating by Student</div>}
                {[1, 2, 3, 4, 5].map(temp => {
                    return <span key={temp}>
                            <img alt="star" className="pointer-on-hover" key={temp - 0.5}
                                 src={currentRating >= temp - 0.5 ? gStar1 : bStar1} onClick={() => {
                                if (!isStudent) return
                                onRatingChange(temp - 0.5)
                            }} height={30} width={15}/>
                            <img alt="star" className="pointer-on-hover" key={temp}
                                 src={currentRating >= temp ? gStar2 : bStar2} onClick={() => {
                                if (!isStudent) return
                                onRatingChange(temp)
                            }} height={30} width={15}/>
                        </span>
                })}
            </div>}
            

        </div>
    )
}