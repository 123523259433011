import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSetLocalUser} from "../../providers/LocalUserProvider";

export default function EmailRegisterAuthentication() {
    const history=useHistory();
    const setLocalUser=useSetLocalUser();
    const [fullname, changeFullName] = useState("");
    const [username, changeUserName] = useState("");
    const [email, changeEmail] = useState("");
    const [password, changePassword] = useState("");
    const [fullNameError,setFullNameError]=useState('');
    const [userNameError,setUserNameError]=useState('');
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    function validateEmail(email: any) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function localFormValidation(){
        if(fullname===''||username===''||email===''||password===''){
            if(fullname===''){
                setFullNameError('This field is required');
            }
            if(username===''){
                setUserNameError('This field is required');
            }
            if(email===''){
                setEmailError('This field is required');
            }
            if(password===''){
                setPasswordError('This field is required');
            }
            return false;
        }
        if(!validateEmail(email)){
            setEmailError("Not a valid e-mail");
            return false;
        }
        if(password.length<6){
            setPasswordError("Passwords must be at-least 6 characters long");
            return false
        }
        return true;
    }
    function onRegisterFormSubmit(e: any) {
        e.preventDefault();
        setEmailError('');
        setFullNameError('');
        setPasswordError('');
        setUserNameError('');
        const pass=password;
        changePassword('');
        if(!localFormValidation())return;
        setLocalUser({fullname,username,email,pass,isNew:true,bio:null,deviceIds:null,pp:null});
        changeFullName('');
        changeUserName('');
        changeEmail('');
        changePassword('');
        history.push('/role-selection/')
    }

    return (
        <div className="d-flex flex-column align-items-center">

            <div className="d-flex align-items-center col-11 col-lg-4 mb-4">
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white number-circle-border m-2">1</div>
                    <div className="number-circle-caption">Sign Up</div>
                </div>
                <hr className="flex-grow-1 bg-light m-0 m-lg-2"/>
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white number-circle m-2">2</div>
                    <div className="number-circle-caption">Profile Detail</div>
                </div>
                <hr className="flex-grow-1 bg-light m-0 m-lg-2"/>
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white number-circle m-2">3</div>
                    <div className="number-circle-caption">Select Category</div>
                </div>


            </div>
        <div className="container text-center modal-content mb-5 col-11 col-lg-4 py-5 px-2 px-lg-5">
            <div className="m-2 my-4 register-modal-heading text-dark">Sign Up</div>
            <form className="form-group container" noValidate={true} onSubmit={onRegisterFormSubmit} style={{maxWidth:0.75*window.innerWidth}}>
                <input value={fullname} onChange={(e) => changeFullName(e.target.value)} type="text" placeholder="Name"
                       className="form-control mt-3 form-input"/>
                <div className="text-danger container text-left small mt-1">{fullNameError}</div>
                <input value={username} onChange={(e) => changeUserName(e.target.value)} type="text"
                       placeholder="User Name" className="form-control mt-3 form-input"/>
                <div className="text-danger container text-left small mt-1">{userNameError}</div>
                <input value={email} onChange={(e) => changeEmail(e.target.value)} type="email" placeholder="Email"
                       className="form-control mt-3 form-input"/>
                <div className="text-danger container text-left small mt-1">{emailError}</div>
                <input value={password} onChange={(e) => changePassword(e.target.value)} type="password"
                       placeholder="Password" className="form-control mt-3 form-input"/>
                <div className="text-danger container text-left small mt-1">{passwordError}</div>
                <div className="d-flex mt-5 px-0 ">
                    <button className="btn btn-block modal-button-text role-selection-button-1 text-dark mt-2 mr-2" onClick={()=>{
                        history.push('/')
                    }}>Back</button>
                    <input type="submit" value="Sign Up" className="btn btn-block modal-button-text role-selection-button-2 mt-2 ml-2"/>
                </div>
            </form>
        </div>
        </div>
    )
}