import {useState} from "react";

export default function SkillCard(props: any) {
    const [selected, setSelected] = useState<boolean>(props.selected ? props.selected : false);

    function onCardClick() {
        if (selected) props.removeFromSelect(props.skill);
        else props.addToSelect(props.skill);
        setSelected(selected => !selected);

    }

    return (
        <span className="popupCard d-flex justify-content-center p-2">
<img alt={props.skill[0]} className={"pointer-on-hover homePageSkillCard " + (selected ? "darken" : "")}
     src={props.skill[1]}
     onClick={onCardClick}/>
        </span>

    )
}