import {
  addCoachDate,
  addEnrolledSession,
  addTask,
  getCoachDate,
  getCoachSession,
  getUserFromUsername,
  sendEmail,
  updateCoachDate,
} from "../../utilities/firebase_app";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { colors, timeHalfHours } from "../../utilities/config";
import DateCard from "./dateCard";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import EnrolledSessionInterface from "../../interfaces/EnrolledSessionInterface";
import { UserContext } from "../../providers/UserProvider";
import BraintreeLayout from "../braintree-layout";
import { getDate } from "../../utilities/utils";
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function BookSlot() {
  const history = useHistory();
  const user: any = useContext(UserContext);
  // const userCredits = user.credits ? user.credits : 0;
  // const setUser = useSetUser();
  const { coach, sessionID }: any = useParams();
  const [session, setSession] = useState<any>();
  const [days, setDays] = useState<any>("");
  const [daysArray, setDaysArray] = useState<number[]>([]);
  const [timeArray, setTimeArray] = useState<Array<string>>([]);
  const [time, setTime] = useState<string>("");
  const [selectedDay, setSelectedDay] = useState("Select a day");
  const [selectedDate, setSelectedDate] = useState<any>();
  const [dateArray, setDateArray] = useState<Date[]>([]);
  const [selectedSlot, setSelectedSlot] = useState("Select a time slot");
  const [coachEmail, setCoachEmail] = useState(coach);
  const [makePayment, setMakePayment] = useState(false);
  useEffect(() => {
    if (!session) return;
    // @ts-ignore
    const divHeight = document.getElementById("backref").offsetHeight;
    // @ts-ignore
    document.getElementById("taxiref").style.height =
      (divHeight - 150).toString() + "px";
  }, [session, selectedDay, selectedDate]);

  function onDayChange(e: any) {
    setSelectedDay(e.target.value);
  }

  function onSlotChange(e: any) {
    setSelectedSlot(e.target.value);
  }

  function onSuccessfulTransaction(): void {
    if (user === undefined) return;
    const _date =
      selectedDate?.getFullYear() +
      "." +
      (selectedDate?.getMonth() + 1).toString().padStart(2, "0") +
      "." +
      selectedDate?.getDate().toString().padStart(2, "0");

    const localEnrolledSession: EnrolledSessionInterface = {
      coachEmail,
      dateEnrolled: new Date().toISOString(),
      dateSelectedByStudentForSession: selectedDate.toISOString(),
      day: selectedDay,
      enrolledSessionDocId: null,
      sessionLink: "",
      studentEmail: user.email,
      timeSlot: selectedSlot,
      timeSlotNumber: timeArray.indexOf(selectedSlot) + 1,
    };
    const [start_time] = getDate(selectedSlot, selectedDate.toISOString());
    addEnrolledSession(localEnrolledSession).then((id) => {
      addTask(start_time, coachEmail, user.email).then(() => {
        updateCoachDate(
          coachEmail,
          sessionID,
          _date,
          timeArray.indexOf(selectedSlot) + 1,
          false,
          timeArray.length
        ).then(() => {
          const text = `Entertainment Coach has scheduled a coaching session for you at ${start_time.toString()}. Please join here: https://entertainmentcoach.com/#/start-session/${id}`;
          sendEmail({
            to: "custom",
            subject: "Coaching Session Booked!",
            body: text,
            email: coachEmail,
          }).then((result) => console.log(result));
          sendEmail({
            to: "custom",
            subject: "Coaching Session Booked!",
            body: text,
            email: user.email,
          }).then((result) => console.log(result));
          setMakePayment(false);
          history.push("/profile");
        });
      });
    });
  }

  function getDates(): Date[] {
    let dateArray = [];
    let currentDate = new Date();
    const stopDate = new Date();
    stopDate.setDate(stopDate.getDate() + 365);
    while (currentDate <= stopDate) {
      const d = new Date(currentDate);
      dateArray.push(d);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  useEffect(() => {
    setSelectedDate(undefined);
  }, [selectedDay]);
  useEffect(() => {
    setSelectedSlot("Select a time slot");
    console.log(selectedDate);
  }, [selectedDate]);
  useEffect(() => {
    setDateArray(getDates());
    getUserFromUsername(coach).then((collection) => {
      if (collection.docs.length === 1)
        setCoachEmail(collection.docs[0].data().email);
      getCoachSession(collection.docs[0].id, sessionID).then((doc) => {
        console.log(doc.data());
        setSession(doc.data());
      });
    });
  }, [coach, sessionID]);
  useEffect(() => {
    if (session) {
      let tempDaysArray: number[] = [];
      let dayOffset = 0;

      let offSet =
        (-new Date().getTimezoneOffset() -
          session.timeZoneOfTheClass / 60000000) /
        60;
      if (!offSet) offSet = 0;
      let startHourOffset = session.startHour + offSet;
      let endHourOffset = session.endHour + offSet;
      if (startHourOffset >= 24) dayOffset = 1;
      else if (startHourOffset < 0) {
        dayOffset = -1;
        startHourOffset += 24;
      }
      if (endHourOffset < 0) endHourOffset += 24;
      let localDays = "";

      session.daysOfThesession.forEach((day: string, index: number) => {
        let tempDayOffset = dayNames.indexOf(day) + dayOffset;
        if (tempDayOffset < 0) tempDayOffset += 7;
        tempDaysArray.push(tempDayOffset % 7);
        if (index === session.daysOfThesession.length - 1)
          localDays += " and " + dayNames[tempDayOffset % 7];
        else if (index === 0) localDays += dayNames[tempDayOffset % 7];
        else localDays += ", " + dayNames[tempDayOffset % 7];
      });
      setDaysArray(tempDaysArray);
      setDays(localDays);

      let tempTimeArray = [];
      let startHourTemp =
        timeHalfHours[(startHourOffset * 2) % timeHalfHours.length];
      let endHourTemp =
        timeHalfHours[(endHourOffset * 2) % timeHalfHours.length];
      for (let j = session.startHour; j !== session.endHour; j = (j + 1) % 24) {
        let tempStartHourOffset = j + offSet;
        let tempEndHourOffset = j + offSet + 1;
        if (tempStartHourOffset < 0) tempStartHourOffset += 24;
        if (tempEndHourOffset < 0) tempEndHourOffset += 24;

        let startHour =
          timeHalfHours[(tempStartHourOffset * 2) % timeHalfHours.length];
        let endHour =
          timeHalfHours[(tempEndHourOffset * 2) % timeHalfHours.length];
        tempTimeArray.push(startHour + "   -  " + endHour + " ");
      }
      setTimeArray(tempTimeArray);
      setTime(startHourTemp + "   -  " + endHourTemp + " ");
    }
  }, [session]);

  async function book() {
    const _date =
      selectedDate?.getFullYear() +
      "." +
      (selectedDate?.getMonth() + 1).toString().padStart(2, "0") +
      "." +
      selectedDate?.getDate().toString().padStart(2, "0");
    getCoachDate(coachEmail, sessionID, _date).then((doc: any) => {
      if (!doc.exists) {
        addCoachDate(
          coachEmail,
          sessionID,
          _date,
          timeArray.indexOf(selectedSlot) + 1,
          timeArray.length
        );
        setMakePayment(true);
        return;
      }
      const slot = doc
        .data()
        .slotData.find(
          (slot: any) => slot.slotNumber === timeArray.indexOf(selectedSlot) + 1
        );
      if (slot && !slot.isAvailable) {
        alert("The coach is not free at this time slot");
      } else {
        setMakePayment(true);
      }
    });
  }

  return (
    <div className="pb-5 d-flex flex-column align-items-center container text-center col-12 col-md-10">
      {session && (
        <Popup
          onClose={() => {
            setMakePayment(false);
          }}
          contentStyle={{ borderRadius: 10, width: "auto", padding: 20 }}
          open={makePayment}
          position="right center"
          modal
        >
          <div className="text-right">
            <button
              className="btn btn-sm rounded-circle"
              onClick={() => {
                setMakePayment(false);
              }}
            >
              &times;
            </button>
          </div>
          <div className="mx-4 mb-4">
            {user && (
              <BraintreeLayout
                amount={session.priceOfTheSession.toString()}
                onSuccess={onSuccessfulTransaction}
              />
            )}
          </div>
          {/*<div className="h6 m-2 text-muted d-flex justify-content-between mt-4"><span>Your Credits: </span><span className="h5 text-info ml-2">${userCredits}</span></div>*/}
          {/*<div className="h6 m-2 text-muted d-flex justify-content-between"><span>Session Price: </span><span className="h5 text-info ml-2">${session.priceOfTheSession}</span></div>*/}
          {/*{userCredits>=session.priceOfTheSession?(*/}
          {/*    <div>*/}
          {/*        <hr/>*/}
          {/*        <div className="h6 m-2 text-muted d-flex justify-content-between"><span>Remaining Credits after booking: </span><span className="h5 text-info ml-2">${userCredits-session.priceOfTheSession}</span></div>*/}
          {/*        <div className="text-center"><button className="btn btn-info rounded-pill m-2" onClick={onSuccessfulTransaction}>Confirm</button></div>*/}
          {/*    </div>*/}
          {/*):(*/}
          {/*    <div className="text-danger">Insufficient credits. Please add credits from the profile section.</div>*/}
          {/*)}*/}
        </Popup>
      )}
      <div
        id="taxiref"
        className="position-absolute p-0 m-0 col-12"
        style={{
          top: "115px",
          left: "0px",
          right: "0px",

          backgroundColor: colors.secondary,
        }}
      />
      {session && (
        <div id="backref" className="h-auto col-11">
          <div className="h2 mb-5 text-dark">Book a new slot</div>
          <div
            className="normal-shadow-left-right bg-white container p-4 mb-3 h4 font-weight-normal"
            style={{ borderRadius: "10px" }}
          >
            {coach + " is available on "}
            <br />
            {days}
            <br />
            from {time}
          </div>
          <div
            className="normal-shadow-left-right  bg-white container pt-3  px-4 pb-4 mb-3 h5 text-center"
            style={{ borderRadius: "10px" }}
          >
            Which day are you available ?
            <select
              className="form-control text-dark dropdown-yellow w-auto container mt-3 text-white"
              style={{ borderRadius: "10px",backgroundColor:colors.primary }}
              onChange={onDayChange}
              value={selectedDay}
            >
              <option value={"Select a day"} className="bg-white text-dark">
                &nbsp;&nbsp;&nbsp;&nbsp;Select a day&nbsp;&nbsp;&nbsp;&nbsp;
              </option>
              {daysArray.map((day: number) => (
                <option key={day} value={dayNames[day]} className="bg-white text-dark">
                  &nbsp;&nbsp;&nbsp;&nbsp;{dayNames[day]}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </option>
              ))}
            </select>
          </div>
          {selectedDay !== "Select a day" && (
            <div
              className="normal-shadow-left-right bg-white container pt-3 px-4 pb-4 mb-3 h5 text-center"
              style={{ borderRadius: "10px" }}
            >
              Which date do you prefer?
              <div className="d-flex flex-wrap mt-3 align-items-center">
                <div className="h4 font-weight-normal mr-4 col-12 col-md-auto">
                  {selectedDay}
                </div>
                <div className="d-flex overflow-auto h-auto">
                  {dateArray.map((date: any) => (
                    <DateCard
                      key={date.getTime()}
                      selectedDay={selectedDay}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      date={date}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {selectedDate && (
            <div
              className="normal-shadow-left-right bg-white container pt-3  px-4 pb-4 mb-4 h5 text-center"
              style={{ borderRadius: "10px" }}
            >
              What time are you available ?
              <select
                className="form-control text-dark dropdown-yellow text-white col-md-3 col-12 container mt-3"
                style={{ borderRadius: "10px",backgroundColor:colors.primary }}
                onChange={onSlotChange}
                value={selectedSlot}
              >
                <option
                  value={"Select a time slot"}
                  className="bg-white text-break text-dark"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Select a Time
                  Slot&nbsp;&nbsp;&nbsp;&nbsp;
                </option>
                {timeArray.map((t) => (
                  <option value={t} key={t} className="bg-white text-dark">
                    &nbsp;&nbsp;&nbsp;&nbsp;{t}&nbsp;&nbsp;&nbsp;&nbsp;
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedSlot !== "Select a time slot" && (
            <button
              className="btn mb-5 rounded-pill p-2 px-4"
              style={{backgroundColor:colors.primary}}
              onClick={book}
            >
              BOOK SESSION
            </button>
          )}
        </div>
      )}
    </div>
  );
}
