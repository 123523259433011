import {useContext, useEffect, useRef, useState} from "react";
import {UserContext, useSetUser} from "../../../providers/UserProvider";
import fire, {generateUserDocument, uploadImage} from "../../../utilities/firebase_app";
import config, { colors } from "../../../utilities/config";
import {useClient} from "../../../providers/AgoraProvider";
import SessionsStudent from "../sessions/sessionsStudent";
import LittleArrow from "../../littleArrow";
import {useHistory} from 'react-router-dom';
import SkillsListMinimal from "../../skill_selection/skillsListMinimal";
export default function Profile() {
    const history=useHistory();
    const user: any = useContext(UserContext);
    const setUser = useSetUser();
    const [coachingSince, setCoachingSince] = useState<number | undefined>();
    const [textareaHeight,setTextAreaHeight]=useState("200px");
    const textareaRef:any=useRef(null);
    const [loading, setLoading] = useState(false);

    function onImageChange(e: any) {
        uploadImage(user, e.target.files[0]).then((user) => {
            setUser(user);
        });
    }

    function changeBio() {
        setLoading(true)
        generateUserDocument(user, {bio}).then(user => {setUser(user);setLoading(false);alert("Bio Updated!")});
    }

    function toggleBio() {
        setBioCollapsed(bioCollapsed => !bioCollapsed);
    }

    useEffect(() => {
        fetch("https://us-central1-entertainment-coach-db.cloudfunctions.net/createdAt/email/?email=" + user.email).then((response: Response) => {
            response.text().then(dateText => {
                const d = new Date(dateText);
                setCoachingSince(d.getFullYear());
            });

        })
    }, [user.email])


    const [bio, setBio] = useState(user.bio);
    // const [username, setUsername] = useState(user.username);
    // const [usernameCollapsed, setUsernameCollapsed]=useState(false);
    const [bioCollapsed, setBioCollapsed] = useState(true);
    useEffect(() => {
        try{
            if(!user.isStudent&&textareaRef.current.scrollHeight>0)
                setTimeout(()=>{
                    setTextAreaHeight(textareaRef.current?.scrollHeight)
                },200)
        } catch (e) {
            console.log(e);
        }


    }, [bioCollapsed,user.email]);
    // const [makePayment,setMakePayment]=useState(false);
    // const [creditsToAdd,setCreditsToAdd]=useState<number>(0);
    // const [enterCredits,setEnterCredits]=useState(false);
    const client = useClient();
    const [skillsCollapsed, setSkillsCollapsed]=useState(false);
    // function changeUserName(){
    //     generateUserDocument(user,{username}).then(user=>setUser(user));
    // }
    function toggleSkills(){
        setSkillsCollapsed(skillsCollapsed=>!skillsCollapsed);
    }
    // function onSuccessfulTransaction(){
    //     generateUserDocument(user,{credits:(user.credits?user.credits:0)+creditsToAdd}).then((user)=>{
    //         setUser(user);
    //         setMakePayment(false);
    //     })
    //
    // }
    // useEffect(()=>{
    //     setTimeout(()=>{
    //
    //         // @ts-ignore
    //         const divHeight = document.getElementById("backref").offsetHeight;
    //         // @ts-ignore
    //         document.getElementById('taxiref').style.height = (divHeight - 160).toString() + "px";
    //     },350)
    // },[usernameCollapsed,skillsCollapsed,bioCollapsed])
    return (
        <div className="text-center container d-flex flex-column align-items-center col-12 col-md-10">
            <div id="backref" className="col-12 col-md-8 pb-5 pt-4 h-auto mb-3 d-flex flex-column align-items-center light-shadow"
                 style={{backgroundColor: colors.secondary, borderRadius: "10px"}}>
                {/*<Popup onClose={() => {
                    setMakePayment(false)
                }} contentStyle={{borderRadius: 10, width: "auto", padding: 20}} open={makePayment} position="right center" modal>
                    <div className="text-right">
                        <button className="btn btn-sm rounded-circle" onClick={() => {
                            setMakePayment(false)
                        }}>
                            &times;
                        </button>
                    </div>
                    <div className="mx-4 mb-4">
                        {user &&
                        <BraintreeLayout amount={creditsToAdd.toString()} onSuccess={onSuccessfulTransaction}/>}
                    </div>
                </Popup>
                <Popup onClose={() => {
                    setEnterCredits(false)
                }} contentStyle={{borderRadius: 10, width: "auto", padding: 20}} open={enterCredits} position="right center" modal>
                    <div className="text-right">
                        <button className="btn btn-sm rounded-circle" onClick={() => {
                            setEnterCredits(false)
                        }}>
                            &times;
                        </button>
                    </div>
                    <div className="text-center mb-4">
                        <label className="my-2" htmlFor="creditsInput">Enter Credits to add in $</label>
                        <input type="number" onChange={(e)=>{setCreditsToAdd(parseInt(e.target.value))}} id="creditsInput" className="form-control my-2 col-12" placeholder="Credits"/>
                        <button className="btn btn-dark rounded-pill mt-2" onClick={()=>{setEnterCredits(false);setMakePayment(true)}}>Add credits</button>

                    </div>
                </Popup>*/}
                <div className="text-right w-100"><img onClick={()=>history.push('/')} alt="close" className="pointer-on-hover m-2" src="https://img.icons8.com/material-two-tone/24/000000/multiply.png"/></div>
                <div className="light-shadow-left-right my-3">
                    <div className="overflow-hidden" style={{borderRadius: "50%", border: "2mm solid", borderColor:colors.primary,width:200,height:200}}>
                    <img alt="user" src={user.pp ? user.pp : config.default_pp}
                         style={{ maxWidth:185,maxHeight:185}}/>
                    </div>
                    

                </div>
                <button style={{backgroundColor:colors.primary}} className="btn rounded-pill mb-3 border border-light text-white" onClick={()=>{
                    document.getElementById('choose_pp')?.click()
                }}
                >
                    Edit Profile Picture<img className='ml-2' alt="Edit Profile" src="https://img.icons8.com/android/20/000000/camera.png" height={20} width={20}/>
                </button>
                <input id="choose_pp" style={{
                    display:"none"
                }} type="file" onChange={onImageChange}/>
                <div className="h3 user-profile-name">{user.fullname}</div>
                {coachingSince && <div
                    className="coaching-since-text text-dark my-2">{user.isStudent ? "Learning" : "Coaching"} since {coachingSince}</div>}

                {/*<div className="text-white">{user.email}</div>*/}
                {/*<div className="my-2 h5 text-white">Credits: ${user.credits?user.credits:0.0}</div>*/}
                {/*<button className="btn btn-dark rounded-pill mb-5" onClick={()=>{setEnterCredits(true)}}>Add credits</button>*/}
                {/*<div  className="card my-3 h-auto" style={{backgroundColor: "#ffe083", borderRadius: "15px"}}>*/}
                {/*    <div className="d-flex card-header pointer-on-hover border-0 justify-content-between" id="headingUsername" data-toggle="collapse"*/}
                {/*         data-target="#collapseUsername" aria-expanded="false" aria-controls="collapseUsername" role="button"*/}
                {/*         style={{backgroundColor: "#ffe083", borderRadius: "15px"}} onClick={toggleUsername}>*/}
                {/*        <span>Edit Personal Information</span>*/}
                {/*        <LittleArrow collapsed={usernameCollapsed}/>*/}
                {/*    </div>*/}

                {/*    <div  id="collapseUsername" className="collapse" aria-labelledby="headingUsername">*/}
                {/*        <div className="card-body">*/}
                {/*            <input type="text" className="form-control border-0"*/}
                {/*                   style={{backgroundColor: "#ffe8a2", borderRadius: "15px"}} value={username}*/}
                {/*                   onChange={(e) => setUsername(e.target.value)}/>*/}

                {/*            <button className="btn btn-dark rounded-pill mt-3" onClick={changeUserName}>UPDATE</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {!user.isStudent &&
                <div className="card my-3 mt-4 col-10 p-0" style={{backgroundColor: "#ffffff", borderRadius: "15px"}}>
                    <div className="card-header pointer-on-hover align-items-center border-0 d-flex justify-content-between col-12"
                         id="headingBio" data-toggle="collapse"
                         data-target="#collapseBio" aria-expanded="true" aria-controls="collapseBio"
                         onClick={toggleBio}
                         style={{backgroundColor: "#ffffff", borderRadius: "15px"}}>
                        <span className="d-flex align-items-center">Edit Bio<img className={'mx-2'} alt="edit" src="https://img.icons8.com/android/12/000000/pencil.png" height={12} width={12}/></span>
                        <LittleArrow collapsed={bioCollapsed} color="black"/>
                    </div>

                    <div id="collapseBio" className="show" aria-labelledby="headingBio">
                        <div className="card-body">
                            <textarea placeholder="Bio"  className=" bio-form form-control border-0"
                                   style={{backgroundColor: "#e1e4e6", borderRadius: "15px", height:textareaHeight}} value={bio ? bio : ''}
                                   ref={textareaRef}
                                      onChange={(e) => {
                                       setBio(e.target.value)

                                          // @ts-ignore
                                          setTextAreaHeight(textareaRef.current?.scrollHeight)
                                   }}/>

                            <button disabled={loading} className="btn btn-outline-dark rounded-pill mt-3" onClick={changeBio}>{loading?"UPDATING":"UPDATE"}
                            </button>
                        </div>
                    </div>
                </div>}
                {!user.isStudent&&
                <div className="card my-3 col-10 p-0" style={{backgroundColor: "white", borderRadius: "15px"}}>
                    <div className="card-header pointer-on-hover border-0 d-flex justify-content-between align-items-center col-12" id="headingSkills" data-toggle="collapse"
                         data-target="#collapseSkills" aria-expanded="false" aria-controls="collapseSkills" onClick={toggleSkills}
                         style={{backgroundColor: "white", borderRadius: "15px"}}>
                        <span className="d-flex align-items-center">Edit Teaching Skills<img className={'mx-2'} alt="edit" src="https://img.icons8.com/android/12/000000/pencil.png" height={12} width={12}/></span>
                        <LittleArrow collapsed={skillsCollapsed}/>
                    </div>

                    <div id="collapseSkills" className="collapse" aria-labelledby="headingSkills">
                        <div className="card-body">
                            <SkillsListMinimal skills={user.skills}/>

                        </div>
                    </div>
                </div>}

                <div className="container text-center mt-5" style={{width: "17em"}}>
                    <button className="btn text-dark rounded-pill btn-block " onClick={() => {
                        client?.leave();
                        fire.auth().signOut()
                    }} style={{backgroundColor:colors.primary}}>LOGOUT
                    </button>
                </div>
                {user.isAdmin&&<div className="container text-center mt-2" style={{width: "17em"}}>
                    <button style={{backgroundColor:colors.primary}} className="btn text-white rounded-pill btn-block mb-5" onClick={() => {
                        history.push('/admin');
                    }}>ADMIN CONSOLE
                    </button>
                </div>}
            </div>
            <SessionsStudent/>
        </div>
    )
}