import config from "../../utilities/config";
import {useHistory} from 'react-router-dom';

export default function SignUpOption() {
    const history = useHistory();
    return (
        <div className="container d-flex flex-column justify-content-between" style={{height: '100%'}}>
            <div className="my-4">
                <h2>Choose a sign up option</h2>
                <div>Create a account today to start your journey</div>
            </div>
            <div>
                <button className="btn btn-light pt-2 pr-4 mt-3 light-shadow-left-right"
                        style={{borderRadius: "10px", backgroundColor: "#dfdfdf", borderWidth: "0px"}} onClick={() => {
                    history.push('/sign-up-email')
                }}>
                    <img src={config.gmail_logo} height="35px" alt="gmail"/><span
                    className="h5">Continue with Email</span>
                </button>
            </div>
            <small className="my-4 fixed-bottom">By Signin up, you agree to our <a href='#/sign-up'>Privacy
                Policy</a>,<a href='#/sign-up'>Cookie Policy</a>, <a href='#/sign-up'>Member Agreement</a>, and we may
                share
                your personal information with our <a href='#/sign-up'>partners</a> to verify your account</small>
        </div>
    )
}