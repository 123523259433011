import { useContext, useEffect, useState } from "react";
import {
    addCoachSessions,
    updateCoachSessions,
} from "../../../utilities/firebase_app";
import { UserContext, useSetUser } from "../../../providers/UserProvider";
import { colors, timeHours } from "../../../utilities/config";
import { useHistory } from "react-router-dom";

export default function Schedule({
    propFrom,
    propDays,
    propPrice,
    sessionID,
    propTo,
}: any) {
    const history = useHistory();
    const isEdit = propFrom !== undefined && propFrom !== -1;
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const user: any = useContext(UserContext);
    const setUser: any = useSetUser();
    const [selectedDays, setselectedDays] = useState<Array<string>>(
        propDays !== undefined ? propDays : []
    );
    const [from, setFrom] = useState(propFrom !== undefined ? propFrom : -1);
    const [to, setTo] = useState(propTo !== undefined ? propTo : -1);
    const [price, setPrice] = useState(
        propPrice !== undefined ? propPrice : ""
    );
    function addSession() {
        if (from === -1) {
            alert("Please select from");
            return;
        }
        if (to === -1) {
            alert("Please select to");
            return;
        }
        if (selectedDays.length === 0) {
            alert("Please select a day");
            return;
        }
        if (price === "") {
            alert("Please enter session price");
            return;
        }
        const daysList = [...selectedDays];
        daysList.sort((a, b) => {
            return days.indexOf(a) - days.indexOf(b);
        });
        if (isEdit) {
            updateCoachSessions(user.email, sessionID, {
                daysOfThesession: daysList,
                slots: selectedDays.length,
                noOfSlots: to - from,
                startHour: from,
                endHour: to,
                timeDuration: timeHours[from] + " - " + timeHours[to],
                priceOfTheSession: parseInt(price),
                timeZoneOfTheClass: new Date().getTimezoneOffset() * -60000000,
            }).then(() => {
                history.push("/");
                setUser((old: any) => ({ ...old }));
            });
        } else {
            addCoachSessions(user.email, {
                daysOfThesession: daysList,
                slots: selectedDays.length,
                noOfSlots: to - from,
                startHour: from,
                endHour: to,
                timeDuration: timeHours[from] + " - " + timeHours[to],
                priceOfTheSession: parseInt(price),
                timeZoneOfTheClass: new Date().getTimezoneOffset() * -60000000,
            }).then(() => {
                history.push("/");
                setUser((old: any) => ({ ...old }));
            });
        }
    }

    function onCheckbox(e: any) {
        const day = e.target.value;
        if (!selectedDays.includes(day)) {
            setselectedDays((old) => {
                old.push(day);
                return [...old];
            });
        } else {
            setselectedDays((old) => {
                old.splice(old.indexOf(day), 1);
                return [...old];
            });
        }
    }
    useEffect(() => {
        // @ts-ignore
        const divHeight = document.getElementById("backref").offsetHeight;
        // @ts-ignore
        document.getElementById("taxiref").style.height =
            (divHeight - 120).toString() + "px";
    }, []);
    return (
        <div className="container col-12 col-md-10 d-flex flex-column align-items-center">
            <div
                id="taxiref"
                className="position-absolute p-0 m-0 "
                style={{
                    top: "85px",
                    left: "0px",
                    right: "0px",

                    backgroundColor: colors.secondary,
                }}
            />
            <div
                id="backref"
                className="container text-left"
                style={{ position: "relative" }}
            >
                
                <div
                    className="bg-white my-3 p-3 text-left normal-shadow-left-right"
                    style={{ borderRadius: "15px" }}
                >
                    <div className="h6 mb-3" style={{ color: colors.text_primary }}>
                    Available Hours
                </div>
                    <div className="m-2" style={{ color: colors.text_primary }}>From</div>
                    <div className="d-flex overflow-auto">
                        {timeHours.slice(0, -1).map((t, index) => (
                            <div
                                key={t + "from"}
                                className={
                                    "btn text-dark btn-outline-warning m-2 p-2 " +
                                    (from === index
                                        ? "active text-white font-weight-bold"
                                        : "text-dark")
                                }
                                aria-pressed="false"
                                onClick={() => {
                                    if (to <= index) setTo(from + 2);
                                    setFrom(index);
                                }}
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {t}
                            </div>
                        ))}
                    </div>
                    <div className="m-2" style={{ color: colors.text_primary }}>To</div>
                    <div className="d-flex overflow-auto">
                        {timeHours.map((t, index) => {
                            if (index < from + 1 + (from === -1 ? 1 : 0)) {
                                return null;
                            }
                            return (
                                <div
                                    key={t + "to"}
                                    className={
                                        "btn text-dark btn-outline-warning m-2 p-2 " +
                                        (to === index
                                            ? "active text-white font-weight-bold"
                                            : "text-dark")
                                    }
                                    aria-pressed="false"
                                    onClick={() => {
                                        setTo(index);
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                        color: "black",
                                    }}
                                >
                                    {t}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div
                    className="bg-white p-3 my-3 normal-shadow-left-right"
                    style={{ borderRadius: "15px " }}
                >
                    <div className="h6 mb-4" style={{ color: colors.text_primary }}>
                        Available Days
                    </div>
                    {days.map((day) => (
                        <div
                            key={day}
                            className="bg-white normal-shadow-left-right px-3 py-1 m-2"
                            style={{ borderRadius: "10px" }}
                        >
                            <input
                                type="checkbox"
                                onChange={onCheckbox}
                                checked={selectedDays.includes(day)}
                                id={day}
                                value={day}
                            />
                            <label
                                className="form-check-label ml-3"
                                htmlFor={day}
                            >
                                {day}
                            </label>
                        </div>
                    ))}
                </div>
                <div
                    className="bg-white normal-shadow-left-right p-3 my-3"
                    style={{ borderRadius: "15px" }}
                >
                    <div
                        className="h6 mb-3"
                        style={{ color: colors.text_primary }}
                    >
                        Price
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Session Price"
                        value={price}
                        onChange={(e) => {
                            setPrice(e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="text-center container my-3">
                <button
                    style={{ backgroundColor: colors.primary }}
                    className="btn rounded-pill mb-5 light-shadow-left-right px-5"
                    onClick={addSession}
                >
                    {isEdit ? "UPDATE " : "ADD "}
                    SESSION
                </button>
            </div>
        </div>
    );
}
