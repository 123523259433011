import { useEffect, useRef, useState } from "react";
import CoachCard from "./coachCard";
import { getCoaches, getOneCoachSession } from "../utilities/firebase_app";
import { blackList, colors } from "../utilities/config";

export default function CoachesList(props: any) {
    const [coaches, setCoaches] = useState<any>([]);
    const rawCoaches: any = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        switch (props.sortBy) {
            case "a-z": {
                setCoaches((old: any) => {
                    const test = old.sort((a: any, b: any) =>
                        a.fullname.localeCompare(b.fullname)
                    );
                    return [...test];
                });
                break;
            }
            case "z-a": {
                setCoaches((old: any) => {
                    const test = old.sort((a: any, b: any) =>
                        b.fullname.localeCompare(a.fullname)
                    );
                    return [...test];
                });

                break;
            }
            case "highest rated": {
                console.log(coaches);
                setCoaches((old: any) => {
                    const test = old.sort(
                        (a: any, b: any) => b.rating - a.rating
                    );
                    return [...test];
                });
                break;
            }
            case "price low-high": {
                setCoaches((old: any) => {
                    const test = old.sort(
                        (a: any, b: any) => a.price - b.price
                    );
                    return [...test];
                });
                break;
            }
            case "price high-low": {
                setCoaches((old: any) => {
                    const test = old.sort(
                        (a: any, b: any) => b.price - a.price
                    );
                    return [...test];
                });
                break;
            }
        }
    }, [props.sortBy]);
    useEffect(() => {
        if (rawCoaches.current)
            setCoaches(
                rawCoaches.current.filter((coach: any) => {
                    if (
                        !coach.fullname
                            .toLowerCase()
                            .includes(props.searchText.toLowerCase())
                    )
                        return false;
                    if (props.skills) {
                        for (let x of coach.skills) {
                            if (props.skills.includes(Number(x))) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return true;
                })
            );
    }, [props.skills, props.searchText]);
    useEffect(() => {
        getCoaches().then(async (data: any) => {
            const coachList: any = [];
            await Promise.all(
                data.docs.map(async (doc: any) => {
                    const docdata = doc.data();
                    if (
                        docdata.pp &&
                        docdata.bio !== "" &&
                        !blackList.includes(docdata.username)
                    ) {
                        await getOneCoachSession(docdata.email).then(
                            (result) => {
                                if (result !== null)
                                    coachList.push({
                                        rating: 3,
                                        ...docdata,
                                        price: result.priceOfTheSession,
                                    });
                            }
                        );
                    }
                })
            );
            coachList.sort((a: any, b: any) => {
                const nameA = a.fullname.toUpperCase();
                const nameB = b.fullname.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            rawCoaches.current = coachList;
            if (props.skills) {
                setCoaches(
                    coachList.filter((coach: any) => {
                        for (let x of coach.skills) {
                            if (props.skills.includes(Number(x))) {
                                return true;
                            }
                        }
                        return false;
                    })
                );
            } else setCoaches(coachList);
            setLoading(false);
        });
    }, []);

    return (
        <div className="d-flex flex-column align-items-center p-0 pb-5 col-11">
            <div className="mb-4 col-12 d-flex justify-content-center flex-wrap p-0">
                {coaches.map((coach: any) => (
                    <CoachCard key={coach.email} coach={coach} />
                ))}
            </div>

            {!loading && coaches.length === 0 && (
                <div className="text-danger h2">
                    Sorry no coaches available . . .
                </div>
            )}
            {loading && (
                <div className=" h2" style={{ color: colors.primary }}>
                    Fetching coaches...
                </div>
            )}
        </div>
    );
}
