import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import EnrolledSessionInterface from "../interfaces/EnrolledSessionInterface";

const firebaseConfig = {
    apiKey: "AIzaSyDc1JXYZiBWLv8XQoHwjSzP8So-HaFvGLc",
    authDomain: "entertainment-coach-db.firebaseapp.com",
    databaseURL: "https://entertainment-coach-db.firebaseio.com",
    projectId: "entertainment-coach-db",
    storageBucket: "entertainment-coach-db.appspot.com",
    messagingSenderId: "388089501692",
    appId: "1:388089501692:web:cce27ceec312ddade48479",
    measurementId: "G-TRG2QGWGP1"
};

let fire = firebase.initializeApp(firebaseConfig);
export default fire;
const providerGoogle = new firebase.auth.GoogleAuthProvider();
const providerFacebook = new firebase.auth.FacebookAuthProvider();

export const uploadImage=async (user:any,file:any)=>{
    const ref=fire.storage().ref(`${user.email}/pp/${file.name}`);
    await ref.put(file);
    const url=await ref.getDownloadURL();
    return generateUserDocument(user,{pp:url});
}

export async function uploadFile (path:string,file:File):Promise<string|null>{
    const ref=fire.storage().ref(path);
    await ref.put(file);
    const url=await ref.getDownloadURL();
    if(url) return url;
    return null;
}

export const signInWithGoogle = () => {
    fire.auth().signInWithPopup(providerGoogle);
};
export const signInWithFacebook = () => {
    fire.auth().signInWithPopup(providerFacebook);
};
export const generateUserDocument = async (user: any, additionalData: any = undefined) => {
    if (!user) return;
    const userRef = fire.firestore().doc(`users/${user.email}`);
    const snapshot = await userRef.get();
    if (additionalData) {
        try {

            await userRef.set((snapshot.exists ? {...snapshot.data(), ...additionalData} : {...additionalData}));
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }

    return getUserDocument(user.email);
};
export const getUserDocument = async (email: string) => {
    if (!email) return null;
    try {
        const userDocument = await fire.firestore().doc(`users/${email}`).get();
        return {
            ...userDocument.data()
        };
    } catch (error) {
        return null

    }
};

export async function getUsers() {
    const snapshot = await fire.firestore().collection('users').get();
    return (snapshot)

}
export async function getCoaches(){
    const snapshot = await fire.firestore().collection('users').where('isStudent','==',false).get();
    return (snapshot)
}
export async function getEnrolledSessions() {
    const snapshot = await fire.firestore().collection('enrolledSessions').get();
    return (snapshot)

}
export async function getEnrolledSession(id:string) {
    const snapshot = await fire.firestore().collection('enrolledSessions').doc(id).get();
    return (snapshot)

}
export async function getUserFromUsername(username:string) {
    const snapshot = await fire.firestore().collection('users').where('username','==',username).get();
    return (snapshot)

}

export async function getCoachSessions(email: string) {
    const snapshot = await fire.firestore().collection('users').doc(email).collection('sessions').get();
    return (snapshot)

}
export async function getOneCoachSession(email: string) {
    const snapshot = await fire.firestore().collection('users').doc(email).collection('sessions').get();
    if(snapshot.docs.length===0)return null
    return snapshot.docs[0].data();
}
export async function getCoachSession(email: string, id: string) {
    const snapshot = await fire.firestore().collection('users').doc(email).collection('sessions').doc(id).get();
    return (snapshot)

}
export async function deleteCoachSession(email:string) {
    const data= await fire.firestore().collection('users').doc(email).collection('sessions').get();
    const batch = fire.firestore().batch();
    data.docs.forEach( doc=>{
        console.log(doc.data())
         batch.delete(doc.ref);
    })
    return await batch.commit();
}
export async function addCoachSessions(email: string, additonalData = {}) {
    const snapshot = await fire.firestore().collection('users').doc(email).collection('sessions').add({
        dateTheSessionAdded: new Date().toISOString(),
        sessionDocId: null,
        sessionModelDocId: null,
        ...additonalData
    });
    return (snapshot)

}

export async function getCoachDate(coachEmail: string , sessionID: string, date:string ){
    const snapshot = await fire.firestore().collection('users').doc(coachEmail).collection('sessions').doc(sessionID).collection('dates').doc(date).get();
    return (snapshot)
}

 export async function updateCoachDate(coachEmail: string , sessionID: string, date:string , slotNumber:number, isAvailable:boolean, noOfSlots:number){
    const doc:any=await getCoachDate(coachEmail,sessionID,date);
    if(doc.exists){
        const docData={...doc.data()};
        const index=docData.slotData.findIndex((slot: any)=>(slot.slotNumber===slotNumber));
        if(index===-1){
            docData.slotData.push({isAvailable,slotNumber})
        }
        else docData.slotData[index].isAvailable=isAvailable;
        await fire.firestore().collection('users').doc(coachEmail).collection('sessions').doc(sessionID).collection('dates').doc(date).update(docData);
    }
    else{
        await addCoachDate(coachEmail,sessionID,date,slotNumber, noOfSlots);
    }
 }
export async function addCoachDate(coachEmail: string , sessionID: string, date:string , slotNumber:number, noOfSlots:number){
    let slotData=[];
    for(let i=0;i<noOfSlots;i++){
        slotData.push({slotNumber:i+1,isAvailable:true})
    }
    await fire.firestore().collection('users').doc(coachEmail).collection('sessions').doc(sessionID).collection('dates').doc(date).set({slotData});
}
export async function updateCoachSessions(email: string,sessionID:string, additonalData: any = {}) {
    await fire.firestore().collection('users').doc(email).collection('sessions').doc(sessionID).update({
                ...additonalData
            });

}

export async function addEnrolledSession(enrolledSession:EnrolledSessionInterface){
    const docRef=await fire.firestore().collection('enrolledSessions').add(enrolledSession);
    return docRef.id;
}
export async function getUserChats(email:string){
    return await fire.firestore().collection('users').doc(email).collection('chats').get()
}
export async function getAuthUser(email:string){
    // @ts-ignore
    return await fire.auth().getUserByEmail(email);
}

export const sendEmail = fire.functions().httpsCallable('sendEmail');

export async function addTask(date:Date,coach:string,student:string){
    const d=new Date(
        date.toLocaleString("en-US", {
            timeZone: "UTC",
        })
    );
    const task={
        year:d.getFullYear(),
        month:d.getMonth()+1,
        date:d.getDate(),
        hour:d.getHours(),
        minutes:d.getMinutes(),
        coachEmail:coach,
        studentEmail:student
    }
    return await fire.firestore().collection('tasks').add(task);
}
export async function getConversionRates(){
    return await fire.firestore().collection('data').doc('conversionRates').get();
}