import {useHistory} from 'react-router-dom';
import {useLocalUser, useSetLocalUser} from "../../providers/LocalUserProvider";
import {useEffect, useState} from "react";

export default function RoleSelection() {
    const history = useHistory();
    const setLocalUser= useSetLocalUser();
    const localUser= useLocalUser();
    const [isStudent,setIsStudent]=useState<boolean|null>(null);
    useEffect(()=>{
        if(!localUser.hasOwnProperty('email')){
            history.push('/sign-up-email');
        }
    },[history,localUser])
    return (
        <div className="d-flex flex-column align-items-center">

            <div className="d-flex align-items-center col-11 col-lg-4 mb-4">
                <div className="d-flex flex-column align-items-center">
                    <div className="number-circle-tick m-2">
                        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1977 0.697476C12.4442 0.462782 12.7722 0.333034 13.1126 0.335609C13.4529 0.338184 13.779 0.472882 14.0219 0.71128C14.2649 0.949678 14.4057 1.27313 14.4147 1.61338C14.4237 1.95363 14.3002 2.28407 14.0702 2.53498L7.08765 11.2675C6.96759 11.3968 6.82268 11.5006 6.66158 11.5726C6.50049 11.6447 6.32653 11.6835 6.15009 11.6867C5.97366 11.69 5.79838 11.6577 5.63473 11.5916C5.47108 11.5256 5.32242 11.4273 5.19765 11.3025L0.567154 6.67198C0.438202 6.55182 0.334773 6.40692 0.263037 6.24592C0.191301 6.08492 0.152728 5.91112 0.149618 5.73489C0.146509 5.55866 0.178927 5.38361 0.244939 5.22018C0.310951 5.05675 0.409204 4.90829 0.533837 4.78366C0.65847 4.65903 0.806929 4.56077 0.970358 4.49476C1.13379 4.42875 1.30884 4.39633 1.48507 4.39944C1.6613 4.40255 1.8351 4.44112 1.9961 4.51286C2.15709 4.5846 2.30199 4.68802 2.42215 4.81698L6.08665 8.47973L12.1644 0.735977C12.1753 0.722503 12.187 0.70965 12.1994 0.697476H12.1977Z" fill="white"/>
                        </svg>

                    </div>
                    <div className="number-circle-caption">Sign Up</div>
                </div>
                <hr className="flex-grow-1 bg-light m-0 m-lg-2"/>
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white number-circle-border m-2">2</div>
                    <div className="number-circle-caption">Profile Detail</div>
                </div>
                <hr className="flex-grow-1 bg-light m-0 m-lg-2"/>
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white number-circle m-2">3</div>
                    <div className="number-circle-caption">Select Category</div>
                </div>


            </div>
        <div className="container text-center col-11 col-lg-4 px-0 py-5 modal-contents">

            <div className="h1 my-5 role-selection-modal-heading">You Want To</div>
            <div className="container text-center mb-3 px-5">

                <button className={"btn border-0 btn-block mb-3 px-4 py-3 "+(isStudent===true?"role-selection-button-3":"role-selection-button-4")} onClick={() => {
                    setIsStudent(old=>old===true?null:true)
                }}>Learn
                </button>
                <button className={"btn border-0 btn-block role-selection-button-3 px-4 py-3 "+(isStudent===false?"role-selection-button-3":"role-selection-button-4")} onClick={() => {
                    setIsStudent(old=>old===true||old===null?false:null)
                }}>Teach
                </button>
            </div>
            <div className="d-flex mt-5 px-5">
                <button className="btn btn-block modal-button-text role-selection-button-1 text-dark m-2" onClick={()=>{
                    history.push('/sign-up-email')
                }}>Back</button>
                <button disabled={isStudent===null}  className="btn btn-block modal-button-text role-selection-button-2 m-2" onClick={()=>{
                    setLocalUser({...localUser,isStudent});
                    history.push('/skill-selection/');
                }}>Next</button>
            </div>
        </div>
        </div>
    )
}