import {Link, useHistory} from "react-router-dom";
import config from "../../utilities/config";
import {useContext, useEffect, useState} from "react";
import LittleArrow from "../littleArrow";
import {Modal} from "react-bootstrap";
import LoginAuthentication from "../authentication/login_authentication";
import facebook_logo from "../../static/facebook_logo.jpg";
import instagram_logo from "../../static/instagram_logo.jpg";
import twitter_logo from "../../static/twitter_logo.png";
import './landingPage.scss';
import video from '../../static/Video.png';
import line from '../../static/line.png';
import footerLine from '../../static/footerLine.png';
import phone from '../../static/phone.png';
import email from '../../static/email.png';
import place from '../../static/place.png';
import landingPageLogo from '../../static/landingPageLogo.png';
import Categories from "./categories/categories";
import {UserContext} from "../../providers/UserProvider";
import poster from '../../static/bgVideoThumbnail.jpg';
export default function LandingPage() {
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [registerModalShow, setRegisterModalShow] = useState(false);
    const user: any = useContext(UserContext);
    useEffect(()=>{
        document.getElementById("main-div")?.classList.remove("mt-5");
        return ()=>document.getElementById("main-div")?.classList.add("mt-5");
    },[])

    function openLoginModal() {
        setLoginModalShow(true);
    }

    const FAQs = [
        [
            "WHAT IS ENTERTAINMENT COACH?",
            [
                "Entertainment Coach is an international platform connecting actors, comedians, performers, creatives and media industry professionals with anyone wanting to improve their chances of career success in the entertainment industry.",
            ],
            false,
        ],
        [
            "HOW DOES IT WORK?",
            [
                "Actors / Comedians / Performers etc, become Coaches by creating an online profile listing their skills and availability for a 60 minute session.",
                "Learners, wanting to work with Coaches create and account and check out the biogs of the coaches along with their skills, price range and availability.",

                "Just click on the profile of the Coach, the day and time slot. Payment is made at the time the session is booked. Coach and Learner are sent a link and reminder before the session.",
                "At the time of the session Entertainment Coach connects the Coach and Learner via their webcam / App for one to one live coaching session.",
                "All payments are made via PAYPAL and all major credit / debit cards.",
            ],
            false,
        ],
        [
            "HOW MUCH DOES IT COST?",
            [
                "Creating an account and downloading the app is no cost. Coaches are not limited to any hourly rate. The average rate is approximately $60 US per hour. Entertainment Coach deducts 20% and makes the 80% payment to coaches every 10 business days.\n",
            ],
            false,
        ],
        [
            "CAN I WORK WITH COACHES IN OTHER COUNTRIES?\n",
            [
                "Yes. Time zones permitting Learners from any country can work with Coaches from the other side of the world. Learners in Mumbai, Melbourne, Tokyo and Kuala Lumpur can work with coaches in New York, Paris, London, Los Angeles and vice versa.",
            ],
            false,
        ],
    ];
    const [faqQuestions, setFaqQuestions] = useState<Array<any>>(FAQs);

    function FAQ(index: number) {
        return (
            <div
                key={index}
                className="card h-auto faq-card"
            >

                <div
                    className="d-flex card-header pointer-on-hover border-0 justify-content-between"
                    id={"headingUsername" + index.toString()}
                    data-toggle="collapse"
                    data-target={"#collapseUsername" + index.toString()}
                    aria-expanded="false"
                    aria-controls={"collapseUsername" + index.toString()}
                    role="button"
                    onClick={() => {
                        setFaqQuestions((old) => {
                            old[index][2] = !old[index][2];
                            return [...old];
                        });
                    }}
                >
                    <span className="font-weight-bold text-left ">
                        {faqQuestions[index][0]}
                    </span>
                    <LittleArrow collapsed={faqQuestions[index][2]}/>
                </div>

                <div
                    id={"collapseUsername" + index.toString()}
                    className="collapse"
                    aria-labelledby={"headingUsername" + index.toString()}
                >
                    <div className="card-body text-left">
                        {faqQuestions[index][1].map((ans: string) => (
                            <div className="h6" key={ans}>
                                {ans}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    const history = useHistory();
    return (
        <div className="text-center landingPage">
            <Modal
                show={loginModalShow}
                onHide={() => setLoginModalShow(false)}
                className="p-0"
                contentClassName="modal-contents"
                centered
            >
                <Modal.Header closeButton={true}/>
                <Modal.Body className="text-center pb-4">
                    <LoginAuthentication/>
                    <div className="container text-center modal-text mt-5 mb-2">
                        New User?{" "}
                        <button
                            className="p-0 modal-text unstyled-link btn btn-link"
                            onClick={() => {
                                setLoginModalShow(false);
                                setRegisterModalShow(true);
                            }}
                        >
                            Sign Up
                        </button>
                    </div>
                    <Link
                        to="/reset-password"
                        className="modal-text unstyled-link"
                    >
                        Forgot Password?
                    </Link>
                </Modal.Body>
            </Modal>
            <Modal
                show={registerModalShow}
                onHide={() => setRegisterModalShow(false)}
                className="p-0"
                contentClassName="modal-contents"
                centered
            >
                <Modal.Header closeButton={true}/>
                <Modal.Body className="text-center">
                    <div className="mb-4 modal-heading">
                        Join Entertainment Coach
                    </div>
                    <div
                        className="m-4 register-modal-tab d-flex align-items-center justify-content-center pointer-on-hover"
                        onClick={() => {
                            history.push("/sign-up-email");
                        }}
                    >
                        <svg
                            width="30"
                            height="24"
                            viewBox="0 0 30 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M27 6L15 13.5L3 6V3L15 10.5L27 3V6ZM27 0H3C1.335 0 0 1.335 0 3V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H27C27.7956 24 28.5587 23.6839 29.1213 23.1213C29.6839 22.5587 30 21.7956 30 21V3C30 2.20435 29.6839 1.44129 29.1213 0.87868C28.5587 0.31607 27.7956 0 27 0Z"
                                fill="#292929"
                            />
                        </svg>
                        <div className="mx-3">SIGN UP WITH EMAIL</div>
                    </div>
                    <div className="container text-center modal-text mt-5 mb-2">
                        Already Registered?{" "}
                        <button
                            onClick={() => {
                                setRegisterModalShow(false);
                                setLoginModalShow(true);
                            }}
                            className="modal-text p-0 btn btn-link unstyled-link "
                        >
                            <u>Sign In</u>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {!user&&<div className="navigationBar">
                <div className="container ">
                    <div className=" logoSection ">
                        <img src={landingPageLogo} className="logo" alt="main logo"/>
                    </div>
                    <div className="buttonSection">
                        <button onClick={openLoginModal} className="signInButton">Sign In</button>
                        <button onClick={()=>setRegisterModalShow(true)} className="signUpButton">Sign Up</button>
                    </div>

                </div>
            </div>}
            <div className='banner position-relative'>
                <video poster={poster} id="bannerVideo" autoPlay loop muted>
                    <source src="https://firebasestorage.googleapis.com/v0/b/entertainment-coach-db.appspot.com/o/videos%2FbgVideo_Trim.mp4?alt=media&token=5308eb9e-e6cf-4ff8-9316-c6e858f73543"/>
                </video>
                <div className="container-fluid">
                    <div className="items">
                        <div className="textBox">
                            <div className="text">
                                <div className="row m-0 justify-content-center">
                                    <span id="learn" className="col">LEARN</span>
                                    <span id="skills" className="col">SKILLS</span>
                                </div>
                                <div className="row m-0 justify-content-center">
                                    <span id="share" className="col">SHARE</span>
                                    <span id="skills2" className="col">SKILLS</span>
                                </div>

                            </div>
                        </div>
                        <div className="buttonSection">
                            <button onClick={()=>setRegisterModalShow(true)} className='landingPageButton-bookNow'>Book Now</button>
                            <button onClick={()=>setRegisterModalShow(true)} className='landingPageButton-explore'>Explore</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="roleSelection">
                <div className="container">
                    <div className="role-card">
                        <div className="heading">Coach</div>
                        <div className="body">Earn money offering coaching or advice sessions to students interested in
                            your profession.
                        </div>
                        <button onClick={()=>setRegisterModalShow(true)} className="landingPageButton-getStarted">Get Started For Free</button>
                    </div>
                    <div className="role-card">
                        <div className="heading">Student</div>
                        <div className="body">Find a coach on-demand to learn new skills 24/7 with professionals
                            globally.
                        </div>
                        <button onClick={()=>setRegisterModalShow(true)} className="landingPageButton-getStarted">Get Started For Free</button>
                    </div>
                </div>
            </div>
            <div className="categories">
                <div className="heading">Categories</div>
                <div className="sub-heading">Select any skills that you are interested in.</div>
                <img src={line} alt={'line'} className='line'/>

                <Categories openModal={()=>setRegisterModalShow(true)}/>
            </div>
            <div className="connectLive">
                <div className="container-fluid">
                    <div className="col-12 col-lg-5 image">
                        <img className="videoImage" src={video} alt="video"/>
                    </div>
                    <div className="col-12 col-lg-5 text">
                        <div className="heading">
                            Connect Live 1:1<br/>Via Video Chat - Anytime, Anywhere
                        </div>
                        <div className="body">
                            Entertainment coach is a one-on-one streaming video platform that connects students and
                            industry professionals across the world. From acting, comedy and writing to business, advice
                            and more. Entertainment Coach delivers a world-class online learning experience tailored to
                            your goals.
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq">
                <div className=" container">
                    <div
                        className=" heading "
                    >
                        FREQUENTLY ASKED QUESTIONS
                    </div>
                    <img src={line} alt={'line'} className='line'/>
                    <div className="faqs">
                        {faqQuestions.map((faq, index) => FAQ(index))}

                    </div>
                </div>
            </div>


            <footer

            >
                <ul className="list-unstyled details m-2">

                    <li className="logo">
                        <img
                            src={config.main_logo}
                            height={100}
                            className="m-2 pointer-on-hover"
                            alt="main_logo"
                            onClick={() => {
                                history.push("/");
                            }}
                        />
                    </li>
                    <li className="slogan">
                        Learn Skills, Share Skills
                    </li>
                    <li className="address">
                        <img className="detailIcons" src={place} alt="place"/>
                        Las Vegas, NV
                    </li>
                    <li className="email">
                        <img className="detailIcons" src={email} alt="email"/>

                        admin@entertainmentcoach.com
                    </li>
                    <li className="phone">
                        <img className="detailIcons" src={phone} alt="phone"/>

                        +1 877-262-2480
                    </li>
                </ul>
                <div className=" footer-columns">
                    <ul className="list-unstyled m-2">
                        <div className="font-weight-bold column-heading">Explore</div>
                        <img src={footerLine} className="footer-line" alt="footer line"/>

                        <li>
                            Press
                        </li>
                        <li>
                            <a href="https://apps.apple.com/gh/app/entertainment-coach/id1527876176">iOS App</a>

                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.entertainmentcoach.entertainmentcoachnew">Android App</a>

                        </li>

                    </ul>
                    <ul className="list-unstyled m-2">
                        <div className="font-weight-bold column-heading">About</div>
                        <img src={footerLine} className="footer-line" alt="footer line"/>

                        <li>
                            Press
                        </li>


                    </ul>
                    <ul className="list-unstyled m-2">
                        <div className="font-weight-bold column-heading">Social</div>
                        <img src={footerLine} className="footer-line" alt="footer line"/>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/entcoach" >Facebook</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://instagram.com/entertainmentcoachapp" >Instagram</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://youtube.com/channel/UC-Y9MYNE9OX_-vkI8KX53Cw" >Youtube</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/entertainment-coach" >LinkedIn</a>
                        </li>

                    </ul>
                    <ul className="list-unstyled m-2">
                        <div className="font-weight-bold column-heading">LET'S CONNECT</div>
                        <img src={footerLine} className="footer-line" alt="footer line"/>

                        <li>
                            Contact Us
                        </li>
                        <li>
                            <img onClick={()=>{window.open('https://www.facebook.com/entcoach','_blank')}} className="m-1 pointer-on-hover" src={facebook_logo} height={20} alt="facebook"/>
                            <img onClick={()=>{window.open('https://instagram.com/entertainmentcoachapp','_blank')}} className="m-1 pointer-on-hover" src={instagram_logo} height={20} alt="facebook"/>
                            <img onClick={()=>{window.open('https://twitter.com/entcoach','_blank')}} className="m-1 pointer-on-hover" src={twitter_logo} height={20} alt="facebook"/>
                        </li>
                    </ul>
                </div>

            </footer>
        </div>
    );
}
