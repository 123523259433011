import React, {useContext, useEffect, useState} from 'react';
import {getUserChats, getUserDocument} from "../../utilities/firebase_app";
import {UserContext} from "../../providers/UserProvider";
import config from "../../utilities/config";
import {useHistory} from "react-router-dom";

function ChatList() {
    const history=useHistory();
    const [chats,setChats]=useState<any>([]);
    const [chatNotEmpty,setChatNotEmpty]=useState(true);
    const user:any=useContext(UserContext);
    useEffect(()=>{
        if(!user)return
        getUserChats(user.email).then(async (snapshot)=>{
            let tempChats:any=[]
            if(snapshot.docs.length===0)setChatNotEmpty(false);
            await snapshot.docs.forEach( (doc,index)=>{
                getUserDocument(doc.data().email).then(user2=>{
                    tempChats.push({...doc.data(),user:user2})
                    if(index===snapshot.docs.length-1) {
                        setChats(tempChats);
                        if(tempChats.length===0)setChatNotEmpty(false);
                    }

                });


            })

        })
    },[user])

    function onChatClick(index:number){
        history.push('/chats/'+chats[index].user.username);
    }

    return (
        <div className="container">
            {chats.map((chat:any,index:number)=>{
                return (
                    <div className="d-flex chat-card align-items-center p-3 pointer-on-hover m-3 light-shadow" onClick={()=>{onChatClick(index)}} key={chat.user.email}>
                        <div id="coachSpan" className="py-1 px-1 p-md-0 d-flex align-items-center">
                            <img alt="coach" className="chat-span" src={(chat.user.pp ? chat.user.pp : config.default_pp)}/>
                        </div>
                        <div>
                            <div className="h4 mx-3 my-2">{chat.user.fullname}</div>
                            <div className="mx-3 my-2 text-left">{chat.user.isStudent?"Student":"Coach"}</div>
                        </div>

                    </div>
                    )

            })}

            {!chatNotEmpty&&<div className="h3 text-danger">NO CHATS FOUND</div>}
        </div>
    );
}

export default ChatList;