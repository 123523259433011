import {useState} from "react";

export default function SkillCardMinimal(props: any) {
    const [selected, setSelected] = useState(props.selected);
    function onCardClick(){
        if(selected)props.removeFromSelect(props.index);
        else props.addToSelect(props.index);
        setSelected((selected:any)=>!selected);

    }
    return (
        <div className={"btn btn-outline-warning edit-teaching-skills text-dark rounded-pill m-1 px-3 "+(selected?'active text-white':'text-dark')} aria-pressed='false' onClick={onCardClick} style={{textTransform: 'capitalize'}}>{props.skill[0]}</div>
    )
}