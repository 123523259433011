import {getEnrolledSessions} from "../../../utilities/firebase_app";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../providers/UserProvider";
import SessionsCard from "./sessionsCard";
import {dateCheck} from "../../../utilities/utils";
import { colors } from "../../../utilities/config";

export default function SessionsStudent() {
    const [upcomingSessions, setUpcomingSession] = useState<any>([]);
    const [pastSessions, setPastSession] = useState<any>([]);
    const [liveSessions, setLiveSession] = useState<any>([]);
    const [refresh,setRefresh]=useState(false);
    const user: any = useContext(UserContext);

    function refreshComponent(){
        setRefresh(old=>!old);
    }
    useEffect(() => {
        getEnrolledSessions().then(data => {
            const sessionList: any = [];
            data.docs.forEach(doc => {
                if (user.isStudent && doc.data().studentEmail === user.email) {
                    sessionList.push({...doc.data(), id: doc.id});
                }
                if (!user.isStudent && doc.data().coachEmail === user.email) {
                    sessionList.push({...doc.data(), id: doc.id});

                }
            })
            sessionList.sort((a: any, b: any) =>
                // @ts-ignore
                new Date(b.dateSelectedByStudentForSession) - new Date(a.dateSelectedByStudentForSession)
            )
            let flag = false;
            let liveFound=-1;
            let liveSessionsTemp = [];
            for (let i = 0; i < sessionList.length; i++) {
                const dateCheckResult = (dateCheck(sessionList[i].timeSlot, sessionList[i].dateSelectedByStudentForSession))
                if (dateCheckResult === 0) {
                    liveSessionsTemp.push(sessionList[i]);

                    liveFound=i;
                    flag = true;
                    const upComingSession = sessionList.slice(0, i);
                    upComingSession.reverse();
                    setUpcomingSession(upComingSession);
                }
                if (dateCheckResult === -1) {
                    if(liveFound===-1){
                        const upComingSession = sessionList.slice(0, i);
                        upComingSession.reverse();

                        setUpcomingSession(upComingSession);


                    }
                    flag = true;
                    setPastSession(sessionList.slice(i,sessionList.length));

                    break;

                }
            }
            setLiveSession(liveSessionsTemp);
            if (!flag) setUpcomingSession(sessionList)
        })
    }, [user.email, user.isStudent,refresh])

    return (<div className="p-0 container text-center d-flex flex-column align-items-center col-12 col-md-10">

        <div id="backref" className="col-12 col-md-10 mb-5 p-0">
            {liveSessions.length!==0 && <div>
                <div className="upcoming-sessions-text my-5" style={{ color:colors.text_primary }}>Live Sessions</div>
                {liveSessions.map((session: any) => <SessionsCard isStudent={user.isStudent}
                                                                      key={session.dateEnrolled}
                                                                      session={session}
                                                                      pastSession={false}
                refresh={refreshComponent}/>)}
            </div>}
            {upcomingSessions.length!==0 && <div>
                <div style={{color:colors.text_primary}} className="upcoming-sessions-text my-5">Upcoming Sessions</div>
                {upcomingSessions.map((session: any) => <SessionsCard isStudent={user.isStudent}
                                                                      key={session.dateEnrolled}
                                                                      session={session}
                                                                      pastSession={false}
                                                                      refresh={refreshComponent}/>)}
            </div>}

            {pastSessions.length!==0 && <div>
                <div style={{color:colors.text_primary}} className="upcoming-sessions-text my-5 ">Completed Sessions</div>

                {pastSessions && pastSessions.map((session: any) => <SessionsCard isStudent={user.isStudent}
                                                                                  key={session.dateEnrolled}
                                                                                  session={session}
                                                                                  pastSession={true}
                                                                                  refresh={refreshComponent}/>)}
            </div>}
        </div>

    </div>)
}