import {timeHalfHours} from "../../../../utilities/config";

export default function CoachSessionCard({session}:any){
    // const history=useHistory();
    const offSet=(-new Date().getTimezoneOffset()-session.timeZoneOfTheClass/60000000)/60;
    const startHour=(timeHalfHours[(session.startHour+offSet)*2%timeHalfHours.length]);
    const endHour=(timeHalfHours[(session.endHour+offSet)*2%timeHalfHours.length]);
    // function onSessionClick(){
    //     const from=parseInt(session.startHour);
    //     const to=parseInt(session.endHour);
    //     const sessionDays=session.daysOfThesession;
    //     let days="0000000"
    //     const daysName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    //     for(let i=0;i<7;i++){
    //         if(sessionDays.includes(daysName[i])){
    //             days = days.substring(0, i) + '1' + days.substring(i + 1);
    //         }
    //     }
    //     history.push(`/schedule/from=${from}&to=${to}&days=${days}&price=${session.priceOfTheSession}&sessionID=${session.id}`)

    // }
    return(
        <div className="flex-wrap d-flex align-items-center justify-content-center justify-content-between bg-white my-3 py-3 px-1 px-md-4 normal-shadow-left-right pointer-on-hover" style={{borderRadius:"10px",overflow:"hidden"}}>
            <div className="m-1 mx-md-2 text-center col-md-auto col-sm-12" style={{whiteSpace: "nowrap"}}>{startHour+" - "+endHour}</div>
            <div className="d-flex overflow-auto mx-2">
                {session.daysOfThesession.map((day:any)=>{
                    return <div key={day} className="light-shadow-left-right p-2 px-3 m-2 bg-white" style={{whiteSpace: "nowrap"}}>{day}</div>
                })}
            </div>

        </div>
    )

}