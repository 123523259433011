import React, {useContext, useEffect, useRef, useState} from 'react';
import fire, {getUserFromUsername} from "../../utilities/firebase_app";
import {UserContext} from "../../providers/UserProvider";
import {useParams} from "react-router-dom";
import ChatBubble from "./chatBubble";
import { colors } from '../../utilities/config';

function ChatMain() {
    const user: any = useContext(UserContext);
    const [userFound, setUserFound] = useState(true);
    const {recipientUsername}: any = useParams();
    const [recipientName,setRecipientName]=useState('');
    const [localMessage,setLocalMessage]=useState('');
    const [chats,setChats]=useState<any>([]);
    const recipientEmail=useRef();

    function onMessageSend(e:any){
        e.preventDefault()
        fire.firestore().collection('Chats').add({
            createdAt:new Date().toISOString(),
            isImage:false,
            message:localMessage,
            messageId:new Date().toISOString(),
            receiveremail:recipientEmail.current,
            senderEmail:user.email
        }).then(
            doc=>{
                setLocalMessage("")
                fire.firestore().collection('Chats').doc(doc.id).update({messageId:doc.id})
            }
        )
    }
    useEffect(()=>{
        document.getElementById("main-div")?.classList.remove("mt-5");
        return ()=>{
            document.getElementById("main-div")?.classList.add("mt-5");

        }
    },[])
    useEffect(()=>{
        const navEleHeight=document.getElementById('navbar-main')?.offsetHeight;
        const headerHeight=document.getElementById("header")?.offsetHeight;
        const inputHeight=document.getElementById("input-tab")?.offsetHeight;
        const windowHeight=window.innerHeight;
        const chatEle=document.getElementById("chats");
        if(chatEle&&navEleHeight&&inputHeight&&headerHeight){chatEle.style.height=(windowHeight-inputHeight-headerHeight-navEleHeight).toString()+'px';}

    },[chats])
    useEffect(() => {

        if(!user)return
        getUserFromUsername(recipientUsername).then(collection => {
            if (collection.docs.length !== 1) {
                setUserFound(false);
                return;
            }
            if((user.isStudent&&collection.docs[0].data().isStudent)||(!user.isStudent&&!collection.docs[0].data().isStudent)){
                setUserFound(false);
                return;
            }
            recipientEmail.current=(collection.docs[0].data().email);
            setRecipientName(collection.docs[0].data().fullname)
            fire.firestore().collection('Chats').where('receiveremail', 'in', [user.email,recipientEmail.current]).onSnapshot(snapshot => {
                let tempChats2:any=[];

                snapshot.forEach(doc=>{
                    if([user.email,recipientEmail.current].includes(doc.data().senderEmail))
                    tempChats2.push(doc.data())
                })
                tempChats2.sort((a: { createdAt: string | number | Date; }, b: { createdAt: string | number | Date; })=>{
                    // @ts-ignore
                    return new Date(a.createdAt)-new Date(b.createdAt);
                })
                let elem = document.getElementById('chats');
                if(elem)
                    elem.scrollTop = elem.scrollHeight;
                setChats(tempChats2);

            })
        })

    }, [user,recipientUsername] )
    if(userFound && recipientName!=='')
    return (
        <div className="d-flex flex-column">
            <div id="header" className="text-dark h3 p-3 m-0">{recipientName}</div>
            <div id="chats" className="flex-grow-1 bg-white overflow-auto " style={{height:"0"}}>
                {chats.map((chat:any)=>{
                    return <ChatBubble key={chat.messageId} text={chat.message} byMe={user.email === chat.senderEmail}/>
                })}
            </div>
            <form onSubmit={onMessageSend} style={{backgroundColor:colors.primary}} className="d-flex p-1" id="input-tab">
                <input onChange={(e)=>{setLocalMessage(e.target.value)}} type="text" className="form-control m-2 chat-input" value={localMessage} placeholder="Say Hi"/>

                <button type="submit" className="btn pointer-on-hover m-2" disabled={localMessage===''} ><img alt="send message" src="https://img.icons8.com/small/28/000000/filled-sent.png"/></button>
            </form>
        </div>
    );
    else if(recipientName===''&&userFound) return (
        <div className="h3 m-5" style={{color:colors.primary}}>LOADING</div>
    )
    else return (
        <div className="h3 text-danger m-5">USER NOT FOUND</div>
    )
}

export default ChatMain;