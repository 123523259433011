import config, { colors } from "../utilities/config";
import { useHistory } from "react-router-dom";
import {
    useConvertedPrice,
    useCurrencySymbol,
} from "../providers/metadata_context";

export default function CoachCard({ coach }: any) {
    const history = useHistory();
    const convertedPrice = useConvertedPrice(coach.price);
    const currencySymbol = useCurrencySymbol();

    return (
        <div
            className="d-flex coach-div w-100 bg-white p-2 rounded20 my-3 pointer-on-hover"
            onClick={() => {
                history.push("/user/" + coach.username);
            }}
        >
            <div
            id="coachSpan"
                style={{ backgroundColor: colors.secondary }}
                className="overflow-hidden p-2 coach-span d-flex align-items-center justify-content-center"
            >
                <img
                    alt="coach"
                    style={{ maxWidth: "100%",maxHeight:"100%", borderRadius:15}}
                    src={coach.pp ? coach.pp : config.default_pp}
                />
            </div>
            <div className="d-flex flex-column p-2" style={{width:"80%"}}>
              <div className="bg-warning rounded20 m-2 p-2">{coach.fullname}</div>
              <p className="m-2 flex-grow-1 coach-bio">{coach.bio}</p>
              <div className="d-flex justify-content-between">
                <div>Price: {currencySymbol}{convertedPrice}</div>
                <button className="btn btn-warning">Book Now</button>
              </div>
            </div>
        </div>
    );
}
