import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getOneCoachSession } from "../../utilities/firebase_app";

export default function AdminCoachCard({ coach }: any) {
    const [live, setLive] = useState<boolean | undefined>();
    const history = useHistory();
    useEffect(() => {
        if (coach)
            getOneCoachSession(coach.email).then((data) => {
                if (data && coach.pp && coach.bio !== "") setLive(true);
                else setLive(false);
            });
    }, [coach]);
    return (
        <div
            onClick={() => history.push("/user/" + coach.username)}
            className={(live ? "bg-success" : "bg-danger") + " p-2 rounded20 light-shadow text-light "}
        >
            <h5>{coach.fullname}</h5>
        </div>
    );
}
