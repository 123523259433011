import {useHistory,useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../providers/UserProvider";
import { colors } from "../utilities/config";

export default function NavigationBar() {
    const location=useLocation();
    const user: any = useContext(UserContext);
    const [curr, setcurr] = useState(0);
    let navLinks:any;
    if (user.isStudent) {
        navLinks = [
            [
                "Home",
                "/home",
                "https://img.icons8.com/material-sharp/24/000000/home.png",
            ],
            [
                "Search",
                "/search",
                "https://img.icons8.com/material-two-tone/20/000000/search.png",
            ],
            [
                "Profile",
                "/profile",
                "https://img.icons8.com/material/24/000000/person-male.png",
            ], [
                "Chats",
                "/chats",
                "https://img.icons8.com/fluent-systems-regular/24/000000/chat-message.png"
            ]
        ];
    } else {
        navLinks = [
            [
                "Home",
                "/home",
                "https://img.icons8.com/material-sharp/24/000000/home.png",
            ],
            [
                "Session",
                "/session",
                "https://img.icons8.com/ios/24/000000/plus--v1.png",
            ],
            [
                "Profile",
                "/profile",
                "https://img.icons8.com/material/24/000000/person-male.png",
            ],
            [
                "Chats",
                "/chats",
                "https://img.icons8.com/fluent-systems-regular/24/000000/chat-message.png"
            ]
        ];
    }
    const history = useHistory();
    useEffect(()=>{
        let flag=true;
        navLinks.forEach((link:any,index:number)=>{
            if(link[1]==="/"+history.location.pathname.split('/')[1]){
                setcurr(index)
                flag=false;
            }
        })
        if(flag)setcurr(0)
    },[location,navLinks,history.location.pathname])

    return (
        <nav
            className="navbar navbar-light light-shadow-bottom navbar-expand p-0"
            id="navigation"
            style={{backgroundColor: colors.secondary}}
        >
            <div className={"navbar-nav justify-content-around w-100 "} id="navbar-main" >
                {navLinks.map((link:any, index:number) => (
                    <li
                        className="nav-item w-100 p-1 nav-bar-items noselect d-flex flex-column justify-content-center"
                        key={link[0]}
                        onClick={() => {
                            setcurr(index);
                            history.push(link[1]);
                        }}
                    >
                        <div>
                            <img height={curr !== index ? 20 : 15} width={curr !== index ? 20 : 15} alt="home"
                                 src={link[2]} className={""}/>
                        </div>
                        {curr === index && <div className="">{link[0]}</div> }
                    </li>
                ))}
            </div>
        </nav>
    );
}
