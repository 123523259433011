import React from 'react';
import { colors } from '../../utilities/config';

function ChatBubble({text,byMe}:any) {
    return (
        <div className={"m-3 d-flex "+(byMe&&"justify-content-end")}>
            <div className={"h-auto text-break p-2 rounded light-shadow tri-right "+(byMe?"top-right":"top-left")} style={{maxWidth:"300px",backgroundColor:byMe?colors.secondary:"white"} }>
                {text}
            </div>
        </div>

    );
}

export default ChatBubble;