import React, { useContext } from "react";
import "./App.css";
import { UserContext } from "./providers/UserProvider";
import {
    HashRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import LandingPage from "./components/landingPage/landingPage";
import EmailRegisterAuthentication from "./components/authentication/email_register_authentication";
import LoginAuthentication from "./components/authentication/login_authentication";
import SkillsList from "./components/skill_selection/skillsList";
import RoleSelection from "./components/authentication/roleSelection";
import SignUpOption from "./components/authentication/sign-up-options";
import NavigationBar from "./components/navigationBar";
import { LocalUserProvider } from "./providers/LocalUserProvider";
import Profile from "./components/navigationCards/profile/profile";
import HomeStudent from "./components/navigationCards/home/student/homeStudent";
import UserProfile from "./components/userProfile/userProfile";
import ResetPassword from "./components/authentication/reset_password";
import BookSlot from "./components/bookSlot/bookSlot";
import SessionStart from "./components/agora/sessionStart";
import IncomingCall from "./components/incomingCall";
import ChatMain from "./components/chat/chat_main";
import ChatList from "./components/chat/chatList";
import CoachesList from "./components/coachesList";
import Admin from "./components/admin/admin";
import CoachSession from "./components/navigationCards/home/coach/coachSession";
import Loading from "./components/loading";
import PublishStatus from "./components/publish_status";
import "./static/styles.scss";
function App() {
    const user: any = useContext(UserContext);
    document.body.style.backgroundColor="#f4f4f4";
    if (user === null) return <Loading />;
    return (
        <div className="text-center">
            {
                <Router>
                    <IncomingCall />
                    <PublishStatus />

                    {user && <NavigationBar />}
                    <div className="mt-5" id="main-div">
                        <Switch>
                            <Route exact path="/">
                                {user ? (
                                    <Redirect to="/home/" />
                                ) : (
                                    <LandingPage />
                                )}
                            </Route>

                            <Route path="/sign-up">
                                {user ? <Redirect to="/" /> : <SignUpOption />}
                            </Route>
                            <Route path="/sign-up-email">
                                {user ? (
                                    <Redirect to="/" />
                                ) : (
                                    <LocalUserProvider>
                                        <EmailRegisterAuthentication />
                                    </LocalUserProvider>
                                )}
                            </Route>
                            <Route path="/sign-in">
                                {user ? (
                                    <Redirect to="/" />
                                ) : (
                                    <LoginAuthentication />
                                )}
                            </Route>
                            <Route path="/reset-password">
                                {user ? <Redirect to="/" /> : <ResetPassword />}
                            </Route>
                            <Route path="/skill-selection">
                                {user ? (
                                    <Redirect to="/" />
                                ) : (
                                    <LocalUserProvider>
                                        <SkillsList />
                                    </LocalUserProvider>
                                )}
                            </Route>
                            <Route path={"/admin"}>
                                {user && user.isAdmin ? (
                                    <Admin />
                                ) : (
                                    <Redirect to="/" />
                                )}
                            </Route>
                            <Route path="/role-selection">
                                {user ? (
                                    <Redirect to="/" />
                                ) : (
                                    <LocalUserProvider>
                                        <RoleSelection />
                                    </LocalUserProvider>
                                )}
                            </Route>
                            <Route path="/coaches">
                                <div className="d-flex flex-column align-items-center">
                                    <h2 className="font-italic">COACHES</h2>
                                    <CoachesList />
                                </div>
                            </Route>
                            <Route path="/home">
                                {user ? (
                                    user.isStudent ? (
                                        <LandingPage />
                                    ) : (
                                        <LandingPage />
                                    )
                                ) : (
                                    <Redirect to="/" />
                                )}
                            </Route>
                            <Route path="/search/:category">
                                {user ? (
                                    user.isStudent ? (
                                        <HomeStudent />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                ) : (
                                    <Redirect to="/" />
                                )}
                            </Route>
                            <Route path="/search">
                                {user ? (
                                    user.isStudent ? (
                                        <HomeStudent />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                ) : (
                                    <Redirect to="/" />
                                )}
                            </Route>

                            <Route path="/profile">
                                {user ? <Profile /> : <Redirect to="/" />}
                            </Route>
                            <Route path="/book-slot/:coach/:sessionID">
                                {user ? (
                                    user.isStudent ? (
                                        <BookSlot />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                ) : (
                                    <Redirect to="/sign-up" />
                                )}
                            </Route>
                            <Route path="/user/:username">
                                <UserProfile />
                            </Route>

                            <Route path="/start-session/:sessionID">
                                {user ? <SessionStart /> : <Redirect to="/" />}
                            </Route>
                            <Route path="/session" exact>
                                {user ? (
                                    !user.isStudent ? (
                                        <CoachSession />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                ) : (
                                    <Redirect to="/" />
                                )}
                            </Route>
                            <Route path="/chats/:recipientUsername">
                                {user ? (
                                    <ChatMain />
                                ) : (
                                    <Redirect to="/sign-up" />
                                )}
                            </Route>
                            <Route path="/chats/">
                                {user ? (
                                    <ChatList />
                                ) : (
                                    <Redirect to="/sign-up" />
                                )}
                            </Route>
                            <Route>Error 404 : NOT FOUND</Route>
                        </Switch>
                    </div>
                </Router>
            }
        </div>
    );
}

export default App;
