import config from "../../utilities/config";
import SkillCard from "./skillCard";
import {useEffect, useState} from "react";
import {useLocalUser, useSetLocalUser} from "../../providers/LocalUserProvider";
import fire, {generateUserDocument} from "../../utilities/firebase_app";
import {useHistory} from 'react-router-dom';

export default function SkillsList() {
    const history=useHistory();
    const [selected, changeSelected] = useState<Array<string[]>>([]);
    const [loading,setLoading]=useState(false);
    const localUser:any=useLocalUser();
    const setLocalUser=useSetLocalUser();
    useEffect(()=>{
        if(!localUser.hasOwnProperty('email')||!localUser.hasOwnProperty('isStudent')){
            history.push('/sign-up-email');
        }
    },[history,localUser])
    function addToSelect(skill: string[]) {

        changeSelected(selectedOld => {
            selectedOld.push(skill);
            return [...selectedOld];
        })
    }

    function removeFromSelect(skill: string[]) {
        changeSelected(selectedOld => {
            selectedOld.splice(selectedOld.indexOf(skill),1);
            return [...selectedOld];
        });
    }

    function register(){
        if(localUser.hasOwnProperty('email')&&localUser.hasOwnProperty('isStudent')){
            setLoading(true);

            const skills=selected.map((skill:string[])=>config.all_skills.indexOf(skill));
            skills.sort((a, b)=>a - b);
            console.log(localUser)
            // @ts-ignore
            fire.auth().createUserWithEmailAndPassword(localUser.email,localUser.pass).then(
                userData=>{
                    // @ts-ignore
                    delete localUser.pass;
                    generateUserDocument(userData.user,{...localUser,skills}).then(()=>{
                        setLocalUser({});
                        history.push('/search/'+skills.join('&'))

                        setLoading(false);
                    });

                }
            )
        }
        else{
            history.push('/sign-up')
        }

    }

    return (
        <div className="d-flex flex-column align-items-center">

            <div className="d-flex align-items-center col-11 col-lg-4 mb-4">
                <div className="d-flex flex-column align-items-center">
                    <div className="number-circle-tick m-2">
                        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1977 0.697476C12.4442 0.462782 12.7722 0.333034 13.1126 0.335609C13.4529 0.338184 13.779 0.472882 14.0219 0.71128C14.2649 0.949678 14.4057 1.27313 14.4147 1.61338C14.4237 1.95363 14.3002 2.28407 14.0702 2.53498L7.08765 11.2675C6.96759 11.3968 6.82268 11.5006 6.66158 11.5726C6.50049 11.6447 6.32653 11.6835 6.15009 11.6867C5.97366 11.69 5.79838 11.6577 5.63473 11.5916C5.47108 11.5256 5.32242 11.4273 5.19765 11.3025L0.567154 6.67198C0.438202 6.55182 0.334773 6.40692 0.263037 6.24592C0.191301 6.08492 0.152728 5.91112 0.149618 5.73489C0.146509 5.55866 0.178927 5.38361 0.244939 5.22018C0.310951 5.05675 0.409204 4.90829 0.533837 4.78366C0.65847 4.65903 0.806929 4.56077 0.970358 4.49476C1.13379 4.42875 1.30884 4.39633 1.48507 4.39944C1.6613 4.40255 1.8351 4.44112 1.9961 4.51286C2.15709 4.5846 2.30199 4.68802 2.42215 4.81698L6.08665 8.47973L12.1644 0.735977C12.1753 0.722503 12.187 0.70965 12.1994 0.697476H12.1977Z" fill="white"/>
                        </svg>

                    </div>
                    <div className="number-circle-caption">Sign Up</div>
                </div>
                <hr className="flex-grow-1 bg-light m-0 m-lg-2"/>
                <div className="d-flex flex-column align-items-center">
                    <div className="number-circle-tick m-2">
                        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1977 0.697476C12.4442 0.462782 12.7722 0.333034 13.1126 0.335609C13.4529 0.338184 13.779 0.472882 14.0219 0.71128C14.2649 0.949678 14.4057 1.27313 14.4147 1.61338C14.4237 1.95363 14.3002 2.28407 14.0702 2.53498L7.08765 11.2675C6.96759 11.3968 6.82268 11.5006 6.66158 11.5726C6.50049 11.6447 6.32653 11.6835 6.15009 11.6867C5.97366 11.69 5.79838 11.6577 5.63473 11.5916C5.47108 11.5256 5.32242 11.4273 5.19765 11.3025L0.567154 6.67198C0.438202 6.55182 0.334773 6.40692 0.263037 6.24592C0.191301 6.08492 0.152728 5.91112 0.149618 5.73489C0.146509 5.55866 0.178927 5.38361 0.244939 5.22018C0.310951 5.05675 0.409204 4.90829 0.533837 4.78366C0.65847 4.65903 0.806929 4.56077 0.970358 4.49476C1.13379 4.42875 1.30884 4.39633 1.48507 4.39944C1.6613 4.40255 1.8351 4.44112 1.9961 4.51286C2.15709 4.5846 2.30199 4.68802 2.42215 4.81698L6.08665 8.47973L12.1644 0.735977C12.1753 0.722503 12.187 0.70965 12.1994 0.697476H12.1977Z" fill="white"/>
                        </svg>

                    </div>
                    <div className="number-circle-caption">Profile Detail</div>
                </div>
                <hr className="flex-grow-1 bg-light m-0 m-lg-2"/>
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white number-circle-border m-2">3</div>
                    <div className="number-circle-caption">Select Category</div>
                </div>


            </div>
        <div className="container col-11 col-lg-7 mb-4 modal-contents p-2 p-lg-5 py-3">

            <div className="h2 my-4 searchModalHeading">What do you want to {localUser?.hasOwnProperty('isStudent')&&localUser.isStudent?"learn":"coach"}</div>
            <div className="my-5 d-flex flex-wrap">
                {config.all_skills.map(skill => (<SkillCard key={skill[0]} skill={skill} addToSelect={addToSelect}
                                                            removeFromSelect={removeFromSelect} height={120} />))}
            </div>
            <div className="d-flex col-12 col-lg-8 m-auto"><button className="btn btn-block modal-button-text role-selection-button-1 text-dark m-2" onClick={()=>{
                history.push('/role-selection')
            }}>Back</button>
            <button disabled={selected.length===0||loading}  className="btn btn-block modal-button-text role-selection-button-2 m-2" onClick={register}>{loading?"Loading...":"Let's go"}</button>
            </div>
        </div>
        </div>
    )
}