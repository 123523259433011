import { colors, timeHalfHours } from "../../utilities/config";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import {
  useConvertedPrice,
  useCurrencySymbol,
} from "../../providers/metadata_context";

export default function UserProfileSessionsCard({ session, username }: any) {
  const history = useHistory();
  const user: any = useContext(UserContext);
  const convertedPrice = useConvertedPrice(session.priceOfTheSession);
  const currencySymbol = useCurrencySymbol();
  let offSet =
    (-new Date().getTimezoneOffset() - session.timeZoneOfTheClass / 60000000) /
    60;
  if (!offSet) offSet = 0;
  const startHour =
    timeHalfHours[((session.startHour + offSet) * 2) % timeHalfHours.length];
  const endHour =
    timeHalfHours[((session.endHour + offSet) * 2) % timeHalfHours.length];

  function onSessionClick() {
    if (!user) return;
    if (!user.isStudent) return;
    history.push(`/book-slot/${username}/${session.id}`);
  }

  return (
    <div
      className={
        "p-2 d-flex flex-column align-items-center user-profile-session-card mx-auto mx-md-2 my-2 " +
        (user.isStudent ? " pointer-on-hover " : "")
      }
      onClick={onSessionClick}
    >
      <h6 className="text-white my-1">
        Session Price: {currencySymbol}
        {convertedPrice}
      </h6>
      <div className="mx-2 my-1 text-white">{startHour + " - " + endHour}</div>
      <div className="d-flex overflow-auto mx-2 my-1 text-center">
        {session.daysOfThesession.map((day: any) => {
          return (
            <div key={day} className="text-white font-weight-bolder m-1 h5">
              {day.slice(0, 2)}
            </div>
          );
        })}
      </div>
      <button className="btn btn-warning m-2" style={{backgroundColor:colors.primary}}>Book</button>
    </div>
  );
}
