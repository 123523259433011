import React, {createContext, useContext, useState} from "react";

export const LocalUserContext=createContext({});
export const UpdateLocalUserContext=createContext<any>(undefined);

export function useLocalUser(){
    return useContext(LocalUserContext);
}

export function useSetLocalUser(){
    return useContext(UpdateLocalUserContext);
}

export function LocalUserProvider({children}:any){
    const [user,setUser]=useState({});

    function updateUser(newUser:any){
        setUser(newUser);
    }
    return(
        <LocalUserContext.Provider value={user}>
            <UpdateLocalUserContext.Provider value={updateUser}>
                {children}
            </UpdateLocalUserContext.Provider>
        </LocalUserContext.Provider>
    )

}