import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import { getOneCoachSession } from "../utilities/firebase_app";

export default function PublishStatus() {
    const [live, setLive] = useState<boolean | undefined>();
    const user: any = useContext(UserContext);

    useEffect(() => {
        if (user)
            getOneCoachSession(user.email).then((data) => {
                if (data && user.pp && user.bio !== '') setLive(true);
                else setLive(false);
            });
    }, [user]);

    if (!user || user.isStudent || live === undefined) return null;

    return (
        <div className={"d-flex align-items-center justify-content-center"} style={{ height: 30, backgroundColor: live ? "#28A745" : "#FFC107" }}>
            {live
                ? "Your account is live to the public"
                : "Please add your schedule, bio and profile picture in order for your profile to be live to the public"}
        </div>
    );
}
