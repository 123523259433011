import { useParams } from "react-router-dom";
import {
    deleteCoachSession,
    generateUserDocument,
    getCoachSessions,
    getUserFromUsername,
} from "../../utilities/firebase_app";
import { useContext, useEffect, useState } from "react";
import config, { colors } from "../../utilities/config";
import UserProfileSessionsCard from "./userProfileSessionsCard";
import { Button } from "react-bootstrap";
import { UserContext } from "../../providers/UserProvider";

export default function UserProfile() {
    const { username }: any = useParams();
    const localUser: any = useContext(UserContext);
    const [user, setUser] = useState<any>();
    // const localUser: any = useContext(UserContext);
    const [userFound, setUserFound] = useState(true);
    const [sessions, setSessions] = useState<any>([]);
    const [sessionFound, setSessionFound] = useState(true);
    const [coachingSince, setCoachingSince] = useState<number | undefined>();

    // const history = useHistory();

    useEffect(() => {
        setUser(undefined);
        setUserFound(true);
        getUserFromUsername(username).then((collection) => {
            if (collection.docs.length !== 1) {
                setUserFound(false);
                return;
            }
            setUser(collection.docs[0].data());
            getCoachSessions(collection.docs[0].data().email).then(
                (session) => {
                    if (session.docs.length === 0) {
                        setSessionFound(false);
                    } else
                        setSessions([
                            {
                                ...session.docs[0].data(),
                                id: session.docs[0].id,
                            },
                        ]);
                }
            );
            fetch(
                "https://us-central1-entertainment-coach-db.cloudfunctions.net/createdAt/email/?email=" +
                    collection.docs[0].data().email
            ).then((response: Response) => {
                response.text().then((dateText) => {
                    const d = new Date(dateText);
                    setCoachingSince(d.getFullYear());
                });
            });
        });
    }, [username]);

    // function checkAndAdd(local: string, remote: string) {
    //     fire.firestore().collection('users').doc(local).collection('chats').doc(remote).get().then(
    //         snapshot => {
    //             if (!snapshot.exists) {
    //                 fire.firestore().collection('users').doc(local).collection('chats').doc(remote).set({
    //                     email: remote,
    //                     messageid: new Date().toISOString()
    //                 })
    //             }
    //         }
    //     )
    // }

    // function onChatClick() {
    //     checkAndAdd(user.email, localUser.email);
    //     checkAndAdd(localUser.email, user.email);
    //     history.push('/chats/' + user.username);
    // }

    return (
        <div className="user-profile pb-5">
            {!user && !userFound && (
                <div className="h2 text-danger">NOT FOUND</div>
            )}
            {user && userFound && (
                <div className="d-flex flex-wrap">
                    <div className="my-2 container col-12 col-md-6">
                        <img
                            alt="user"
                            className="col-12 col-md-9"
                            src={user.pp ? user.pp : config.default_pp}
                        />
                    </div>
                    <div className="my-2  d-flex flex-column text-left col-12 col-md-6">
                        {user.rating && user.sessionsRated && (
                            <div className="text-dark d-flex align-items-center my-2">
                                <svg
                                    width="22"
                                    height="21"
                                    viewBox="0 0 22 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.0002 1.8335L13.8327 7.57183L20.1668 8.49766L15.5835 12.9618L16.6652 19.2685L11.0002 16.2893L5.33516 19.2685L6.41683 12.9618L1.8335 8.49766L8.16766 7.57183L11.0002 1.8335Z"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <div className="rating-text font-weight-bold mx-1">
                                    {user.rating.toFixed(1)}
                                </div>
                                <div className="rating-text ">
                                    ({user.sessionsRated})
                                </div>
                            </div>
                        )}
                        <div className="user-name mt-2" style={{ color:colors.text_primary }}>{user.fullname}</div>
                        {localUser &&
                            localUser.isAdmin &&
                            user &&
                            user.pp && (
                                <div>
                                    <Button
                                        onClick={() => {
                                          if(window.confirm("Are you sure want to clear the profile picture?"))
                                            generateUserDocument(user, {
                                                pp: null,
                                            }).then(() => {
                                                alert("Bio Cleared");
                                                setUser((old:any) => ({
                                                    ...old,
                                                    pp: null,
                                                }));
                                            });
                                        }}
                                        variant={"danger btn-sm m-2"}
                                    >
                                        Clear Profile Picture
                                    </Button>
                                </div>
                            )}
                        {coachingSince && user && (
                            <div className="coaching-since-text my-2">
                                {user.isStudent ? "Learning" : "Coaching"} since{" "}
                                {coachingSince}
                            </div>
                        )}
                        <div className="about-heading my-2" style={{ color:colors.text_primary }}>About</div>
                        {localUser &&
                            localUser.isAdmin &&
                            user &&user.bio&&
                            user.bio !== '' && (
                                <div>
                                    <Button
                                        onClick={() => {
                                          if(window.confirm("Are you sure want to clear the bio?"))
                                            generateUserDocument(user, {
                                                bio: "",
                                            }).then(() => {
                                                alert("Bio Cleared");
                                                setUser((old:any) => ({
                                                    ...old,
                                                    bio: "",
                                                }));
                                            });
                                        }}
                                        variant={"danger btn-sm m-2"}
                                    >
                                        Clear Bio
                                    </Button>
                                </div>
                            )}
                        <div className="bio-text my-2 pr-2 pl-2 pl-md-0 pr-md-4 text-justify text-md-left">
                            {user.bio}
                        </div>
                        <div className="about-heading my-2" style={{ color:colors.text_primary }}>Schedule</div>
                        {sessionFound &&
                            sessions.length > 0 &&
                            localUser?.isAdmin && (
                                <div className="mx-auto mx-md-2">
                                    <Button
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are you sure you want to delete this coach's sessions?"
                                                )
                                            )
                                                deleteCoachSession(
                                                    user.email
                                                ).then(() => {
                                                    alert(
                                                        "Coach Sessions Delete"
                                                    );
                                                    setSessions([]);
                                                });
                                        }}
                                        variant="danger btn-sm"
                                    >
                                        Delete Sessions
                                    </Button>
                                </div>
                            )}
                        <div className="d-flex flex-wrap my-2 ">
                            {sessions &&
                                sessions.map((session: any) => (
                                    <UserProfileSessionsCard
                                        key={session.dateTheSessionAdded}
                                        username={username}
                                        session={session}
                                    />
                                ))}
                        </div>
                        {!sessionFound && (
                            <h4 className="text-warning">
                                No classes available for this coach
                            </h4>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
