import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./providers/UserProvider";
import AgoraProvider from "./providers/AgoraProvider";
import MetadataContext from "./providers/metadata_context";

ReactDOM.render(
    <UserProvider>
        <AgoraProvider>
            <MetadataContext>
                <App />
            </MetadataContext>
        </AgoraProvider>
    </UserProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
