
import main_logo from "../static/main_logo.png";
import gmail_logo from "../static/gmail_logo.png";
import default_pp from "../static/default_pp.jpeg";
import web_logo from "../static/web_logo.png";
import video_logo from "../static/video_logo.png";
import acting from "../static/skills_logo/acting.svg";
import animation from "../static/skills_logo/animation.svg";
import business from "../static/skills_logo/business.svg";
import circus from "../static/skills_logo/circus.svg";
import comedy from "../static/skills_logo/comedy.svg";
import dance from "../static/skills_logo/dance.svg";
import directing from "../static/skills_logo/directing.svg";
import magic from "../static/skills_logo/magic.svg";
import modeling from "../static/skills_logo/modeling.svg";
import music from "../static/skills_logo/music.svg";
import online from "../static/skills_logo/online.svg";
import production from "../static/skills_logo/production.svg";
import speaking from "../static/skills_logo/speaking.svg";
import vocal from "../static/skills_logo/vocal.svg";
import writing from "../static/skills_logo/writing.svg";

export const colors={
  primary:"#f7c204",
  bg_primary:"#e9e1d6",
  secondary:"white",
  text_primary:"black",
  text_secondary:"white"
}

const config = {
  main_logo,
  gmail_logo,
  default_pp,
  web_logo,
  video_logo,
  all_skills: [
    ["acting", acting],
    ["dance", dance],
    ["comedy", comedy,],
    ["directing", directing, ],
    ["music", music, ],
    ["magic", magic, ],
    ["writing", writing, ],
    ["circus", circus, ],
    ["online", online, ],
    ["animation", animation, ],
    ["vocal", vocal, ],
    ["speaking", speaking, ],
    ["business", business, ],
    ["production", production, ],
    ["modeling", modeling, ],
  ],
  skills_logo_folder: "./static/skills_logo/",
};
export default config;

export const timeHalfHours = [
  "12 : 00 AM",
  "12 : 30 AM",
  "1 : 00 AM",
  "1 : 30 AM",
  "2 : 00 AM",
  "2 : 30 AM",
  "3 : 00 AM",
  "3 : 30 AM",
  "4 : 00 AM",
  "4 : 30 AM",
  "5 : 00 AM",
  "5 : 30 AM",
  "6 : 00 AM",
  "6 : 30 AM",
  "7 : 00 AM",
  "7 : 30 AM",
  "8 : 00 AM",
  "8 : 30 AM",
  "9 : 00 AM",
  "9 : 30 AM",
  "10 : 00 AM",
  "10 : 30 AM",
  "11 : 00 AM",
  "11 : 30 AM",
  "12 : 00 PM",
  "12 : 30 PM",
  "1 : 00 PM",
  "1 : 30 PM",
  "2 : 00 PM",
  "2 : 30 PM",
  "3 : 00 PM",
  "3 : 30 PM",
  "4 : 00 PM",
  "4 : 30 PM",
  "5 : 00 PM",
  "5 : 30 PM",
  "6 : 00 PM",
  "6 : 30 PM",
  "7 : 00 PM",
  "7 : 30 PM",
  "8 : 00 PM",
  "8 : 30 PM",
  "9 : 00 PM",
  "9 : 30 PM",
  "10 : 00 PM",
  "10 : 30 PM",
  "11 : 00 PM",
  "11 : 30 PM",
];
export const timeHours = [
  "12 : 00 AM",
  "1 : 00 AM",
  "2 : 00 AM",
  "3 : 00 AM",
  "4 : 00 AM",
  "5 : 00 AM",
  "6 : 00 AM",
  "7 : 00 AM",
  "8 : 00 AM",
  "9 : 00 AM",
  "10 : 00 AM",
  "11 : 00 AM",
  "12 : 00 PM",
  "1 : 00 PM",
  "2 : 00 PM",
  "3 : 00 PM",
  "4 : 00 PM",
  "5 : 00 PM",
  "6 : 00 PM",
  "7 : 00 PM",
  "8 : 00 PM",
  "9 : 00 PM",
  "10 : 00 PM",
  "11 : 00 PM",
];
export const blackList = ["Ai6119$"];
