import {useState} from 'react';
import fire, {generateUserDocument} from "../../utilities/firebase_app";


export default function LoginAuthentication() {
    const [email, changeEmail] = useState("");
    const [password, changePassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [loading, setLoading] = useState(false);

    function validateEmail(email: any) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function localFormValidation(){
        if(email===''||password===''){
            if(email===''){
                setEmailError('This field is required');
            }
            if(password===''){
                setPasswordError('This field is required');
            }
            return false;
        }
        if(!validateEmail(email)){
            setEmailError("Not a valid e-mail");
            return false;
        }
        if(password.length<6){
            setPasswordError("Invalid Credentials!");
            return false
        }
        return true;
    }
    function onLoginFormSubmit(e: any) {

        e.preventDefault();
        setEmailError('');
        setPasswordError('');
        const pass=password;
        changePassword('');
        if(!localFormValidation())return;
        setLoading(true);
        fire.auth().signInWithEmailAndPassword(email, pass).then((user:any)=>{
            generateUserDocument(user).then(()=>setLoading(false)).catch(error => {
                console.error(error);
                setLoading(false);
            }).catch(()=>setLoading(false));

        }).then(()=>setLoading(false)).catch((error:any)=>{
            switch (error.code) {
                case "auth/network-request-failed": {
                    setPasswordError("Unable to connect to the internet");
                    break;
                }
                case "auth/wrong-password": {
                    setPasswordError("Invalid Credentials!");
                    break;
                }
                case "auth/user-not-found":{
                    setEmailError("No such user found");
                    break;
                }
                default: {
                    setPasswordError(error.code);
                    break
                }
            }
            setLoading(false);
        });

    }

    return (
        <div className="text-center">
            <div className="my-4 modal-heading">Login</div>
            <form className="form-group container text-center px-lg-4" noValidate={true} onSubmit={onLoginFormSubmit}>
                <input required value={email} onChange={(e) => changeEmail(e.target.value)} type="email"
                       placeholder="Email"
                       className="form-control mt-3 form-input"/>
                <div className="text-danger container text-left small mt-1">{emailError}</div>
                <input required value={password} onChange={(e) => changePassword(e.target.value)} type="password"
                       placeholder="Password" className="form-control mt-3 form-input "/>
                <div className="text-danger container text-left small mt-1">{passwordError}</div>
                <div className="container mt-3">

                </div>
                <input disabled={loading} type="submit" value={loading?"Logging In":"Log In"} className="btn btn-block modal-button modal-button-text text-dark my-3 px-3 py-2"/>
            </form>

        </div>
    )
}