import {useEffect, useState} from "react";
import { colors } from "../../utilities/config";

export default function DateCard({date,selectedDay,selectedDate,setSelectedDate}:any){
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [selected,setSelected]=useState(selectedDate===date);
    const disabled=(selectedDay!==dayNames[date.getDay()]);

    function onDateClick(){
        if(!disabled) {setSelectedDate(date)}
    }
    useEffect(()=>{
        if(!selectedDate) {
            setSelected(false);
            return;
        }
        setSelected(selectedDate.getTime()===date.getTime());
    },[selectedDate,date])
    return(
        <div onClick={onDateClick} className={" mx-2 p-2 px-3 "+(!disabled?"pointer-on-hover":"noselect")} style={{borderRadius:"10px",backgroundColor:(selected?colors.primary:'white')}}>
            <div className="" style={{fontSize:"10px",color:(disabled?"grey":(!selected?'black':'black'))}}>
                {monthNames[date.getMonth()].slice(0,3).toUpperCase()}
            </div>
            <div className="" style={{color:(disabled?"grey":(!selected?'black':'black'))}}>
                {date.getDate()}
            </div>
            <div className="mt-1" style={{fontSize:"10px",color:(disabled?"grey":(!selected?'black':'black'))}}>
                {dayNames[date.getDay()].slice(0,3).toUpperCase()}
            </div>

        </div>
    )
}