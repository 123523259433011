import React, {createContext, useContext, useEffect, useState} from 'react';
import AgoraRTC, {IAgoraRTCClient, IRemoteAudioTrack, IRemoteVideoTrack} from "agora-rtc-sdk-ng";
import {RtcRole, RtcTokenBuilder} from 'agora-access-token';
import {UserContext} from "./UserProvider";
const ClientProvider = createContext<IAgoraRTCClient | undefined>(undefined);
const RemoteVideoTrackProvider = createContext<IRemoteVideoTrack | undefined>(undefined);
const RemoteAudioTrackProvider = createContext<IRemoteAudioTrack | undefined>(undefined);
const UserJoinedContext = createContext<string>("");
export const options = {
    appId: "3baf36a6d4d144afb5e1c8215e700aed",
    appCertificate: "e5d1a60930e9451c9cb3fa983d220a6e",
}

export function useUserJoined(){
    return useContext(UserJoinedContext);
}

export function useClient() {
    return useContext(ClientProvider);
}

export function useRemoteVideoTrack() {
    return useContext(RemoteVideoTrackProvider);
}

export function useRemoteAudioTrack() {
    return useContext(RemoteAudioTrackProvider)
}

export function getAgoraToken(userUsername: string, coachUsername: string){
    const expirationTimeInSeconds = 3600
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds
    return RtcTokenBuilder.buildTokenWithAccount(options.appId, options.appCertificate, coachUsername, userUsername, RtcRole.PUBLISHER, privilegeExpiredTs);

}

function AgoraProvider({children}: any) {

    const [client, setClient] = useState<IAgoraRTCClient | undefined>(undefined);
    const [remoteVideoTrack, setRemoteVideoTrack] = useState<IRemoteVideoTrack | undefined>(undefined);
    const [remoteAudioTrack, setRemoteAudioTrack] = useState<IRemoteAudioTrack | undefined>(undefined);
    const [userJoined,setUserJoined] = useState<string>("");
    const user: any = useContext(UserContext);

    useEffect(() => {

        if (!user || user.username === undefined || client !== undefined) return;
        const temp_client = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});
        setClient(temp_client);
        if(!user.isStudent)
        temp_client.join(options.appId, user.username, getAgoraToken(user.username,user.username), user.username).then(() => {
            temp_client.on("user-published", async (remoteUser, mediaType) => {

                if (remoteUser.uid === user.username) return;
                setUserJoined(remoteUser.uid.toString());
                await temp_client.subscribe(remoteUser, mediaType);
                setRemoteVideoTrack(remoteUser.videoTrack);
                setRemoteAudioTrack(remoteUser.audioTrack);
            });
            temp_client.on("user-unpublished", () => {
                setUserJoined("");
                setRemoteAudioTrack(undefined);
                setRemoteVideoTrack(undefined);
            });
        });


    }, [user, client])
    return (
        <ClientProvider.Provider value={client}>
            <RemoteAudioTrackProvider.Provider value={remoteAudioTrack}>
                <RemoteVideoTrackProvider.Provider value={remoteVideoTrack}>
                    <UserJoinedContext.Provider value={userJoined}>
                        {children}
                    </UserJoinedContext.Provider>
                </RemoteVideoTrackProvider.Provider>
            </RemoteAudioTrackProvider.Provider>
        </ClientProvider.Provider>
    );
}

export default AgoraProvider;