import config from "../../utilities/config";
import {useContext, useState} from "react";
import SkillCardMinimal from "./skillCardMinimal";
import {generateUserDocument} from "../../utilities/firebase_app";
import {UserContext, useSetUser} from "../../providers/UserProvider";

export default function SkillsListMinimal(props:any) {
    const [selected, changeSelected] = useState<Array<number>>([...props.skills]);
    const user=useContext(UserContext);
    const setUser=useSetUser();
    function addToSelect(skill: number) {
        changeSelected(selectedOld => {
            selectedOld.push(skill);
            return [...selectedOld];
        })
    }

    function removeFromSelect(skill: number) {
        changeSelected(selectedOld => {
            selectedOld.splice(selectedOld.indexOf(skill),1);
            return [...selectedOld];
        });
    }

    function update(){
        generateUserDocument(user,{skills:selected}).then((user)=> {
            setUser(user);
            setTimeout(()=>alert("Skills Updated"),0);
        });
    }

    return (
        <div className="container">
            <div>
                {config.all_skills.map((skill,index) => (<SkillCardMinimal key={index} skill={skill} addToSelect={addToSelect}
                                                            removeFromSelect={removeFromSelect} index={index} selected={props.skills.includes(index)}/>))}
            </div>
            <button className="btn btn-outline-dark rounded-pill mt-3" onClick={update}>SAVE CHANGES</button>

        </div>
    )
}