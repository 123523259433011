import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {getCoaches, sendEmail, uploadFile} from "../../utilities/firebase_app";
import { useForm } from "react-hook-form";
import AdminCoachCard from "./adminCoachCard";

function Admin() {
    const [emailModal, setEmailModal] = useState(false);
    const [sending, setSending] = useState(false);
    const [uploading,setUploading]=useState(-1);
    const [to, setTo] = useState("coaches");
    const [message, setMessage] = useState("");
    const [coaches, setCoaches] = useState<any[]>([]);
    const { register, handleSubmit } = useForm();
    const [files,setFiles]=useState<File[]>([]);
    const showEmailModal = () => {
        setEmailModal(true);
        setMessage("");
        setTo("coaches");
    };
    const hideEmailModal = () => {
        setEmailModal(false);
        setMessage("");
        setTo("coaches");
    };
    async function onSubmit(data: any) {
        setSending(true);
        setMessage("");
        const { to, subject, body, email } = data;
        if (to === "custom" && email === "") return;


        const uploadPromise:Promise<{filename:string,path:string}[]>=new Promise((res,rej)=>{
            setUploading(0);
            let attachments:{filename:string,path:string}[]=[];
            if(files.length>0){
                files.forEach(file=>{
                    uploadFile('admin/attachments/'+file.name,file).then((url)=>{
                        if(url) attachments.push({filename:file.name,path:url});
                        setUploading(attachments.length);
                        if(attachments.length===files.length){
                            res(attachments)
                        }
                    }).catch((e)=>{
                        rej(e);
                    });
                })
            } else res([])
        })
        uploadPromise.then((attachments)=>{
            setUploading(-1);
            sendEmail({ to, subject, body, email, attachments }).then((result) => {
                if (result.data.pass) {
                    setMessage("Emails sent successfully!");
                } else
                    setMessage("It looks like there is some error sending email.");
                setSending(false);
            });
        }).catch(e=>{
            console.error(e);
            setMessage("ERROR IN UPLOADING FILES!")
        })

    }
    useEffect(() => {
        getCoaches().then((data) => {
            let coachesList: any[] = [];
            data.docs.forEach((doc) => {
                coachesList.push(doc.data());
            });
            setCoaches(coachesList)
        });
    }, []);
    function handleFileChange(e:React.ChangeEvent<HTMLInputElement>){
        const filesArr:File[]=[];
        if(e.target.files){
            for(let i=0;i<e.target.files.length;i++){
                filesArr.push(e.target.files[i]);
            }
            setFiles(filesArr);
            console.log(filesArr)
        }
    }
    return (
        <div className=" text-center container d-flex flex-column align-items-center col-12">
            <Modal centered={true} show={emailModal} onHide={hideEmailModal}>
                <Modal.Header className="text-center m-2" closeButton={true}>
                    <h2 className="w-100">Send Email</h2>
                </Modal.Header>
                <Modal.Body className="m-2">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label >
                                Recipients
                            </Form.Label>
                            <Form.Control
                                onChange={(e) => setTo(e.target.value)}
                                ref={register}
                                name="to"
                                as="select"
                            >
                                <option value="coaches">Coaches</option>
                                <option value="students">Students</option>
                                <option value="all">All</option>
                                <option value="custom">Custom</option>
                            </Form.Control>
                        </Form.Group>
                        {to === "custom" && (
                            <Form.Group>
                                <Form.Label >
                                    To
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    required={true}
                                    name="email"
                                    placeholder="Email"
                                />
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Form.Label >
                                Subject
                            </Form.Label>
                            <Form.Control
                                ref={register}
                                required={true}
                                name="subject"
                                placeholder="Subject"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Body</Form.Label>
                            <Form.Control
                                ref={register}
                                required={true}
                                name="body"
                                as={"textarea"}
                                placeholder="Body"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Attachments</Form.Label>
                            <Form.Control multiple={true} type="file" onChange={handleFileChange}/>
                            {uploading!==-1&&<Form.Text>Uploading Files {uploading}/{files.length}</Form.Text>}
                        </Form.Group>
                        {<Form.Text className="text-info">{message}</Form.Text>}

                        <Button
                            disabled={sending}
                            block={true}
                            variant={"warning"}
                            type={"submit"}
                            className="rounded-pill mt-4"
                        >
                            SEND EMAIL
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <div
                id="backref"
                className="col-12 col-md-8 py-5 h-auto mb-3 d-flex flex-column align-items-center"
                style={{ backgroundColor: "#242526", borderRadius: "10px" }}
            >
                <h2 className="text-white mb-4">Admin Console</h2>
                <Button
                    variant={"warning"}
                    className="rounded-pill"
                    onClick={showEmailModal}
                >
                    SEND EMAIL
                </Button>
            </div>

            <div className="my-3 mb-5 col-10">
                <h2 className="text-white">Coaches List</h2>
                <div style={{ height: 500 }} className="overflow-auto">
                    {coaches.map((coach) => (
                        <div className="m-2 pointer-on-hover">
                            <AdminCoachCard coach={coach} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Admin;
